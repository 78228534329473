import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { GET } from "../utils/request.js";
import axios from "axios";
import Utils from "../utils/config";
import MaterialTable from "material-table";
const API = Utils.API;
class AuditsConfigurations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      usuarios: [],
      dataTable: [],
      auditType: this.props.tipo
    };
    this.hide_PopUp = this.hide_PopUp.bind(this);
  }
  hide_PopUp() {
    this.props.close();
  }
  componentDidMount() {
    let auth = JSON.parse(localStorage.getItem("user"));
    let access_token = auth.access_token;
    if (this.props.id !== undefined) {
      axios
        .get(API + `${this.state.auditType}/${this.props.id}/audit`, {
          // params: { tipo: this.props.tipo, id: this.props.id },
          headers: { Authorization: "Bearer " + access_token },
        })
        .then((res) => {
          this.setState({ show: true, dataTable: res.data.data || res.data });

          GET(this, "usuarios");
        });
    } else {
      axios
        .get(API + "audits", {
          params: { tipo: this.props.tipo },
          headers: { Authorization: "Bearer " + access_token },
        })
        .then((res) => {
          this.setState({ show: true, dataTable: res.data.data || res.data });

          GET(this, "usuarios");
        });
    }
  }
  render() {
    // console.log('diamons: ', rowData.user_id, this.state.usuarios)
    return (
      <div>
        {this.state.dataTable !== [] ?
          <Modal size="xl" centered show={this.state.show} onHide={this.hide_PopUp}>
            <Modal.Header closeButton>
              <Modal.Title>Auditoría</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <MaterialTable
                title=""
                columns={[
                  {
                    title: "id",
                    hidden: "true",
                    defaultSort: "desc",
                    field: "_id",
                  },
                  {
                    title: "Cambio realizado por", field: "user_id", render: (rowData) =>
                      getName(rowData.user_id, this.state.usuarios),
                  },
                  {
                    title: "Valor anterior", field: "old_values", render: (rowData) =>
                      JSON.stringify(rowData.old_values)
                  },
                  {
                    title: "Valor nuevo", field: "new_values", render: (rowData) =>
                      JSON.stringify(rowData.new_values)
                  },
                  { title: "IP", field: "ip_address" },
                  { title: "Tipo de Modificación", field: "event" },
                  { title: "Fecha de Actualización", field: "updated_at", defaultSort: 'asc' },
                ]}
                localization={{
                  header: {
                    actions: "Acciones",
                  },
                  toolbar: {
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                  pagination: {
                    previousTooltip: "Página anterior",
                    nextTooltip: "Página siguiente",
                    firstTooltip: "Primera página",
                    lastTooltip: "Última página",
                  },
                  body: {
                    emptyDataSourceMessage: "No hay registros que mostrar",
                  },
                }}
                options={{
                  search: false,
                  selection: false,
                  searchFieldAlignment: "right",
                  paging: false,
                  pageSize: 10,
                  actionsColumnIndex: -1,
                  draggable: false,
                }}
                data={this.state.dataTable}
              />
            </Modal.Body>
          </Modal>
          : null}
      </div>
    );
  }
}
function getName(id, data) {
  if (data !== undefined) {
    for (var i = 0, len = data.length; i < len; i++) {
      if (data[i].id === id) {
        return data[i].profile.nombre //+ " " + data[i].last_name
      }
    }
  }
}
export default AuditsConfigurations;
