import { ErrorMessage, Form, Formik } from 'formik';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { number, object, string } from 'yup';
import DatePickerField from '../common/DatePicker';
import { Modal } from 'react-bootstrap';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';


function CreateEditLink(props) {
    const [isLoading, setLoading] = useState(true);
    const [organizaciones, setOrganizaciones] = useState([]);
    const [formularios, setFormularios] = useState([]);
    const [responseFormSubmited, setResponseFormSubmited] = useState([]);
    const [popupEnlaceCreated, setPopupEnlaceCreated] = useState(false);
    const [formState, setFormState] = useState({
        descripcion: '',
        tipo: '',
        formulario_id: '',
        organizacion_id: '',
        limite: '',
        expira: '',
        codigo: null,
        url: null
    });

    const enlace_id = props.match.params.enlace_id;

    // load enlace, if editing
    useEffect(() => {
        if (!enlace_id) {
            setLoading(false);
            return;
        }
        req.get(`${API_URL}enlaces/${enlace_id}/show`,).then(response => {
            const enlace = response.data.data;
            const { descripcion, anonimo, organizacion_id, limite, expira, formulario_id, codigo, url } = enlace;
            // console.log({ enlace })
            setFormState({
                descripcion,
                tipo: anonimo ? 'anonimo' : 'no_anonimo',
                formulario_id,
                organizacion_id,
                limite: limite ?? '',
                expira: expira ?? '',
                codigo,
                url
            });
            setLoading(false);
        });

    }, [enlace_id]);

    // load organizaciones and other necessary data
    useEffect(() => {
        req.get(`${API_URL}selectores/organizaciones`).then((response) => setOrganizaciones(response.data.data ?? []));
        req.get(`${API_URL}selectores/formularios`).then((response) => setFormularios(response.data.data ?? []));
    }, []);

    const validationSchema = object().shape({
        descripcion: string().trim().required('Descripción es un campo requerido.'),
        tipo: string().required('Tipo es un campo requerido.'),
        formulario_id: number().required('Formulario es un campo requerido.'),
        organizacion_id: number().required('Organización es un campo requerido.'),
        limite: number().nullable(),
        expira: string().nullable()
    });

    const onSubmit = (values, { setSubmitting }) => {
        setSubmitting(false);
        const isNew = !enlace_id;
        const payload = {
            ...formState, ...values,
            organizacion: values.organizacion_id,
            formulario: values.formulario_id
        };

        delete payload.organizacion_id;
        delete payload.formulario_id;

        if (isNew) {
            delete payload.codigo;
            delete payload.url;
            req.post(`${API_URL}enlaces/store`, payload).then((response) => {
                setResponseFormSubmited(response.data.data);
                setPopupEnlaceCreated(true);
            });

        } else {
            req.post(`${API_URL}enlaces/${enlace_id}/update`, payload).then(() => {
                props.history.push('/enlaces');
            });
        }
    };
    console.log({ formState })
    return (
        <div className='container'>
            <div className='mainBox'>
                {isLoading ? (
                    <div className='text-center'>
                        <div className='spinner-border text-primary' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                        </div>
                    </div>
                ) : (
                    <Formik
                        initialValues={formState}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ isSubmitting, isValid, handleBlur, handleChange, values }) => (
                            <Form>
                                <div className='row'>
                                    {!!enlace_id && (
                                        <>
                                            <div className='form-group col-4'>

                                                <label className='form-label'>Código</label>
                                                <input
                                                    name='codigo'
                                                    value={values.codigo}
                                                    className='form-control'
                                                    disabled
                                                />
                                            </div>
                                            <div className='form-group col-4'>
                                                <label className='form-label'>Url</label>
                                                <input
                                                    name='limite'
                                                    value={values.url}
                                                    className='form-control'
                                                    disabled
                                                />
                                                <ErrorMessage name='limite' className='small text-danger' component='p' />
                                            </div>
                                        </>
                                    )}
                                    <div className='form-group col-4'>
                                        <label className='form-label'>Descripción *</label>
                                        <textarea
                                            name='descripcion'
                                            value={values.descripcion}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFormState((prev) => ({
                                                    ...prev,
                                                    descripcion: e.target.value
                                                }));
                                            }}
                                        ></textarea>
                                        <ErrorMessage name='descripcion' className='small text-danger' component='p' />
                                    </div>

                                    <div className='form-group col-4'>
                                        <label className='form-label'>Tipo *</label>
                                        <select
                                            name='tipo'
                                            value={values.tipo}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            <option value=''>Selecciona...</option>
                                            <option value='anonimo'>Anónimo</option>
                                            <option value='no_anonimo'>No Anónimo</option>
                                        </select>
                                        <ErrorMessage name='tipo' className='small text-danger' component='p' />
                                    </div>

                                    <div className='form-group col-4'>
                                        <label className='form-label'>Formulario *</label>
                                        <select
                                            name='formulario_id'
                                            value={values.formulario_id}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            <option value=''>Selecciona...</option>
                                            {formularios.map(form => (
                                                <option value={form.id}>{form.nombre}</option>
                                            ))}
                                        </select>
                                        <ErrorMessage name='formulario_id' className='small text-danger' component='p' />
                                    </div>

                                    <div className='form-group col-4'>
                                        <label className='form-label'>Organización *</label>
                                        <select
                                            name='organizacion_id'
                                            value={values.organizacion_id}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            <option value=''>Selecciona...</option>
                                            {organizaciones.map((org) => (
                                                <option key={org.id} value={org.id}>
                                                    {org.nombre}
                                                </option>
                                            ))}
                                        </select>
                                        <ErrorMessage name='organizacion_id' className='small text-danger' component='p' />
                                    </div>

                                    <div className='form-group col-4'>
                                        <label className='form-label'>Límite</label>
                                        <input
                                            name='limite'
                                            type='number'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.limite}
                                        />
                                        <ErrorMessage name='limite' className='small text-danger' component='p' />
                                    </div>

                                    <div className='form-group col-4'>
                                        <label className='form-label'>Expira</label>
                                        <DatePickerField name='expira' value={values.expira} />
                                        <ErrorMessage name='expira' className='small text-danger' component='p' />
                                    </div>

                                </div>

                                <div className='row'>
                                    <div className='col-12 text-center'>
                                        <button
                                            type='submit'
                                            className='buttonSubmit'
                                            disabled={isSubmitting || !isValid}
                                        >
                                            {isSubmitting ? 'Guardando...' : 'Guardar'}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
            <Modal
                size="lg"
                centered
                show={popupEnlaceCreated}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ textAlign: 'center', fontWeight: 'bold' }}>¡El enlace fue creada con exito!</p><br />
                    <p style={{ textAlign: 'center', marginBottom: 0 }}>ID: <strong>{`${responseFormSubmited.id}`}</strong></p>
                    <p style={{ textAlign: 'center', marginBottom: 0 }}>Código: <strong>{`${responseFormSubmited.codigo}`}</strong></p>
                    <p style={{ textAlign: 'center', marginBottom: 0 }}>Url: <strong><a target="_blank" rel="noopener noreferrer" href={responseFormSubmited.url} >{`${responseFormSubmited.url}`}</a></strong></p>

                    <p style={{ textAlign: 'center', marginBottom: 0 }}>Descripción : <strong>{`${responseFormSubmited.descripcion}`}</strong></p>
                    <p style={{ textAlign: 'center', marginBottom: 0 }}>Organización: <strong>{organizaciones.find(organizacion => parseInt(organizacion.id) === parseInt(responseFormSubmited.organizacion_id))?.nombre}</strong></p>
                    <p style={{ textAlign: 'center', marginBottom: 0 }}>Formulario: <strong>{`${formularios.find(form => parseInt(form.id) === parseInt(responseFormSubmited.formulario_id))?.nombre}`}</strong></p>
                    <p style={{ textAlign: 'center', marginBottom: 0 }}>Tipo: <strong>{`${responseFormSubmited.anonimo ? 'Anónimo' : 'No Anónimo'}`}</strong></p>

                    <p style={{ textAlign: 'center', marginBottom: 0 }}>Límite: <strong>{responseFormSubmited.limite || 'No'}</strong></p>
                    <p style={{ textAlign: 'center', marginBottom: 0 }}>Fecha de Expiración: <strong>{responseFormSubmited.expira ? `${responseFormSubmited.expira ? format(parseISO(responseFormSubmited.expira), 'dd/MM/yyyy ', new Date()) : null}` : 'No'}</strong></p>

                </Modal.Body>

                <Modal.Footer>
                    <button onClick={() => props.history.push('/enlaces')}>Cerrar</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default withRouter(CreateEditLink);