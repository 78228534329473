export const getTrueFalse = (data, valueTrue, valueFalse)=>{ 
    if(data === true){
        return (
            <div className={valueTrue}>{valueTrue}</div>
        );
     }else{
        return (
        <div className={valueFalse}>{valueFalse}</div>
        );
     }
 }
 export const getName = (id, data)=>{ 
     if(data !== undefined){
        for (var i = 0, len = data.length; i < len; i++) {
            if(data[i]._id === id){
                return data[i].name || data[i].title
            }
        }
    }   
}
export const checkStatus = (status)=>{  
    if (status) {
    return <div className="elementType active"><span>Activo</span></div>
    } else {
    return <div className="elementType disabled"><span>Inactivo</span></div>
    }
}
export const checkStatusAttendance = (status)=>{ 
    if (status) {
    return <div className="elementType active"><span>Participante</span></div>
    } else {
    return <div className="elementType disabled"><span>No Participante</span></div>
    }
}
export const getGender = (gender)=>{ 
    if (gender === "2") {
    return <div className="elementType"><span>Masculino</span></div>
    } else {
    return <div className="elementType"><span>Femenino</span></div>
    }
}
export const changeDateFormat = (date)=>{ 
    if(date === undefined){
        return "";
    }
    let d = date.replaceAll('/', '-').split("-");
    if (date === "") { return false }
    return [d[2], d[1], d[0]].join('/');
}
export const changeDateFormatv2 = (date)=>{ 
    if(date == undefined){
        return "";
    }
    let d = date.replaceAll('/', '-').split("-");
    if (date == "") { return false }
    return [d[0], d[1], d[2]].join('/');
}