import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import AuthService from '../services/auth.service';
import { useLocation } from 'react-router-dom';

const publicRoutes = ['/login', '/olvido_contrasena', '/enlaces/anonimas', '/enlaces/participantes'];

export function SecuredComponent({ children, roles = [] }) {
  const auth = AuthService.getCurrentUser();
  const location = useLocation();

  // console.log({ pathname: location.pathname });
  if (!auth
    && !publicRoutes.some(x => location.pathname.includes(x))
  ) {
    console.log('redirecting');
    return <Redirect to='/login' />;
  }
  if (!Array.isArray(roles)) {
    roles = [roles];
  }
  const isAuthorized =
    roles.length === 0 || roles.includes(auth?.user?.role?.nombre);
  return isAuthorized ? children : null;
}

export default SecuredComponent;
