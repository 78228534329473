import { useEffect, useState } from "react";
import { req } from "../request";
import { queryClient } from "../../App";
import toast from "react-hot-toast"; import { openDB } from 'idb';
import { useDatabase } from "../../services/db-connection";

const DB_NAME = 'offlineMutationsDatabase';
const STORE_NAME = 'pendingMutations';
const pendingMutationsKey = 'pending-mutations';

// // Initialize or open the IndexedDB database
// const initDB = async () => {
//     return openDB(DB_NAME, 1, {
//         upgrade(db) {
//             if (!db.objectStoreNames.contains(STORE_NAME)) {
//                 db.createObjectStore(STORE_NAME, { autoIncrement: true });
//             }
//         },
//     });
// };

// make this service work on global context so no depends on the rendering of a specific component
export function useOfflineSync() {
    const db = useDatabase();
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const pendingMutationsKey = 'pendingMutations'; // Key for storing mutations
    const [triggeredFirstToast, setTriggeredFirstToast] = useState(false);
    const [in2, set2In] = useState(false);
    // console.log({ isOnline })
    // Listen for online/offline changes
    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            // toast.success('Ahora Estas Online')
        };
        const handleOffline = () => {
            setIsOnline(false);
            // toast.success('Ahora Estas Offline');
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);


    useEffect(() => {

        if (triggeredFirstToast) {
            if (isOnline) {
                toast.success('Ahora Estas Online');
            } else {
                toast.success('Ahora Estas Offline');
            }
        } else {
            setTriggeredFirstToast(true);
        }

    }, [isOnline]);

    // Store a mutation in IndexedDB when offline
    const storePendingMutation = async (mutation) => {
        console.log("inside storePendingMutation", mutation)
        const tx = db.transaction(STORE_NAME, 'readwrite');
        const store = tx.objectStore(STORE_NAME);

        const f = await store.add(mutation); // Add the mutation to the store

        await tx.done;


        console.log('done on storePendingMutation', f)
    };

    // Retrieve and clear pending mutations from IndexedDB
    const getPendingMutations = async () => {
        const tx = db.transaction(STORE_NAME, 'readwrite');
        const store = tx.objectStore(STORE_NAME);

        // Get all mutations
        const pendingMutations = await store.getAll();

        // Clear the store once mutations are retrieved
        await store.clear();
        await tx.done;

        return pendingMutations;
    };

    // Sync pending mutations with the server
    const syncPendingMutations = async (pendingMutations) => {
        const mutationFn = async (endpoint, payload) => req
            .post(endpoint, payload)
        // .then((response) => {
        //     // onOnlineSucess && onOnlineSucess(response);
        // });
        console.log('executing syncPendingMutations')
        for (const mutation of pendingMutations) {
            try {
                console.log('Syncing', mutation.endpoint + '...');
                // Execute each pending mutation (assumes an API call)
                const response = await mutationFn(mutation.endpoint, mutation.payload, mutation);
                if (mutation.mutationType === 'update') {
                    queryClient.setQueryData(mutation.keysToRevalidate, items => items.map(item => item.id === response.data.data.id ? response.data.data : item));
                } else if (mutation.mutationType === 'new') {
                    queryClient.setQueryData(mutation.keysToRevalidate, items => [...items, response.data.data]);
                }
                // console.log('Invalidating query...',);
                // await queryClient.invalidateQueries({ queryKey: mutation.keysToRevalidate });
                // mutation?.onSyncSucess && mutation.onSyncSucess();
            } catch (error) {
                console.error('Failed to sync mutation:', mutation, error);
            }
        }
        // After syncing, refetch the data from the server to update the cache
        // queryClient.invalidateQueries(); // You can specify a particular query if needed
    };

    async function performMutation({ endpoint, payload, offlineFn, keysToRevalidate = endpoint, mutationType }) {
        console.log("perform mutation")
        const mutationFn = async () => req
            .post(endpoint, payload)
        // .then((response) => {
        //     onOnlineSucess && onOnlineSucess(response);
        // });

        if (isOnline) {
            const response = await mutationFn();
            // await queryClient.invalidateQueries({ queryKey: keysToRevalidate });

            queryClient.setQueryData(keysToRevalidate, items => {
                if ((items || []).some(item => item.id === response.data.data.id)) {
                    return (items || []).map(item => item.id === response.data.data.id ? response.data.data : item)
                } else {
                    return [...(items || []), response.data.data]
                }
            });
            return response;

        } else {
            console.log("calling storePendingMutation")
            await storePendingMutation({ keysToRevalidate, endpoint, payload, mutationType });
            const response = offlineFn();

            if (mutationType === 'update') {
                queryClient.setQueryData(keysToRevalidate, items => items.map(item => item.id === response.data.data.id ? response.data.data : item));
            } else if (mutationType === 'new') {
                queryClient.setQueryData(keysToRevalidate, items => [...items, response.data.data]);
            }


            return response;
        }
    }

    // When coming back online, sync pending mutations
    useEffect(() => {
        console.log({ in2, isOnline })
        if (in2 && isOnline) {
            console.log('here bro')
            getPendingMutations().then((pendingMutations) => {
                console.log({ pendingMutations });

                if (isOnline && pendingMutations?.length) {

                    syncPendingMutations(pendingMutations);
                }
            });
        } else {
            set2In(true);
        }
    }, [isOnline]);
    return { isOnline, performMutation };
}
