import MultiSelect from "../components/MultiSelect.js";
import { API_URL } from "../utils/config";
import { req } from "../utils/request";
import CustomDatePicker from "./CustomDatePicker.jsx";
import { PatternFormat } from "react-number-format";
import { Button } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { useOfflineSync } from "../utils/offline/useOfflineSync.js";
import { updatePoll } from "../generatePollOffline/generatePolllOffline.js";

export function removeUnderscoreAndHyphen(inputString) {

  // Use regular expressions to replace underscores and hyphens with an empty string
  const cleanedString = inputString.replace(/[_-]/g, "");
  return cleanedString;
}

function convertDateFormatToYYYYMMDD(inputDate) {
  // Split the input date into day, month, and year
  const [day, month, year] = inputDate.split("/");

  // Create a new Date object using the components
  const convertedDate = new Date(`${year}/${month}/${day}`);

  // Get the components of the converted date
  const convertedDay = convertedDate.getDate();
  const convertedMonth = convertedDate.getMonth() + 1; // Months are zero-based, so add 1
  const convertedYear = convertedDate.getFullYear();

  // Pad single-digit day and month with leading zero if necessary
  const formattedDay = convertedDay < 10 ? "0" + convertedDay : convertedDay;
  const formattedMonth =
    convertedMonth < 10 ? "0" + convertedMonth : convertedMonth;

  // Create the formatted date string in "yyyy/mm/dd" format
  const formattedDate = `${convertedYear}/${formattedMonth}/${formattedDay}`;

  return formattedDate;
}

function convertDateFormatToDDMMYYYY(inputDate) {
  // Split the input date into year, month, and day
  const [year, month, day] = inputDate.split("/");

  // Create a new Date object using the components
  const convertedDate = new Date(`${month}/${day}/${year}`);

  // Get the components of the converted date
  const convertedDay = convertedDate.getDate();
  const convertedMonth = convertedDate.getMonth() + 1; // Months are zero-based, so add 1
  const convertedYear = convertedDate.getFullYear();

  // Pad single-digit day and month with leading zero if necessary
  const formattedDay = convertedDay < 10 ? "0" + convertedDay : convertedDay;
  const formattedMonth =
    convertedMonth < 10 ? "0" + convertedMonth : convertedMonth;

  // Create the formatted date string in "dd/mm/yyyy" format
  const formattedDate = `${formattedDay}/${formattedMonth}/${convertedYear}`;

  return formattedDate;
}

function isDateInFormat(dateString) {
  // Check if the date matches the "dd/mm/yyyy" format
  const ddmmYYYYRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (ddmmYYYYRegex.test(dateString)) {
    return "dd/mm/yyyy";
  }

  // Check if the date matches the "yyyy/mm/dd" format
  const yyyyMMddRegex = /^\d{4}\/\d{2}\/\d{2}$/;
  if (yyyyMMddRegex.test(dateString)) {
    return "yyyy/mm/dd";
  }

  // If the date doesn't match either format
  return "Invalid format";
}

export function PollQuestion({
  section,
  item,
  respuestas = [],
  selectedAnswers,
  errors,
  handleChange,
  canEdit,
  form,
  publicPollType,
  encuesta
}) {
  const { isOnline, performMutation } = useOfflineSync();
  const MAX_FILE_SIZE_MB = 100; // Tamaño máximo permitido (100MB)
  const VALID_FILE_TYPES = ["application/pdf", "image/jpeg", "image/png", "application/msword"];
  const idRespuesta = item.respuestas_participante[0].id;
  const [fileData, setFileData] = useState([])
  const fetchFiles = async () => {
    try {
      const response = await req.get(
        `${API_URL}encuestas/respuestas/participantes/${idRespuesta}/show`
      );
      setFileData(response.data.data.documentos);
    } catch (error) {
      console.error("Error al cargar los archivos:", error);
    }
  };

  const [selectedMatrixAnswers, setSelectedMatrixAnswers] = useState({}); // Estado para almacenar las respuestas seleccionadas de la matriz

  useEffect(() => {
    if (isOnline && !publicPollType) {
      fetchFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idRespuesta]);

  // Crear FormData para enviar al backend
  const createFormData = (file) => {
    const formData = new FormData();
    formData.append("documento", file);
    formData.append("descripcion", file.name);
    return formData;
  };

  // Manejar carga de archivos
  const handleFilesChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);

    for (const file of selectedFiles) {
      if (!VALID_FILE_TYPES.includes(file.type)) {
        alert(`El archivo ${file.name} tiene un formato no permitido.`);
        continue;
      }
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        alert(`El archivo ${file.name} supera los 100MB y no fue cargado.`);
        continue;
      }

      const formData = createFormData(file);

      try {
        const response = await req.post(
          `${API_URL}${publicPollType ? 'enlaces' : 'encuestas'}/respuestas/participantes/${idRespuesta}/add-document`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        const newDocument = response.data.data.documentos;
        setFileData((prevData) => [...prevData, newDocument[newDocument.length - 1]]);
      } catch (error) {
        console.error(`Error al subir el archivo ${file.name}:`, error);
      }
    }

    event.target.value = "";
  };
  const handleDeleteServerFile = async (docId) => {
    const confirmDelete = window.confirm(
      "¿Estás seguro de que deseas eliminar este archivo? Esta acción no se puede deshacer."
    );

    if (confirmDelete) {
      try {
        await req.delete(
          `${API_URL}${publicPollType ? 'enlaces' : 'encuestas'}/respuestas/participantes/${docId}/remove-document`
        );
        setFileData((prevData) => prevData.filter((doc) => doc.id !== docId));
      } catch (error) {
        console.error("Error al eliminar el archivo del servidor:", error);
      }
    }
  }

  const handleBlur = ({ section, respuesta: respuesta_v, pregunta }) => {
    let respuesta = Array.isArray(selectedAnswers[`field_${item.id}`])
      ? selectedAnswers[`field_${item.id}`].map((option) => option.value)
      : selectedAnswers[`field_${item.id}`];

    let peso = null;
    console.log({ form })
    if ([1, 2].includes(type.id)) {
      const formSection = form.secciones.find(
        (x) => x.orden === section.orden && x.activo === true
      );
      const formQuestion = formSection.items.find(
        (x) => x.pregunta === pregunta
      );
      if (type.id === 1) {
        const formAnswer = (formQuestion?.respuestas || []).find(
          (x) => x.respuesta === respuesta_v
        );
        peso = formAnswer?.peso;
      } else {
        // here exists a possible problem when the answer uses comma
        respuesta_v.split("|").forEach((respuesta) => {
          const formAnswer = (formQuestion?.respuestas || []).find(
            (x) => x.respuesta === respuesta
          );
          peso = (peso || 0) + (formAnswer?.peso || 0);
        });
      }
    } else if (type.id === 5) {
      if (respuesta === "Si") {
        peso = 1;
      } else if (respuesta === "No") {
        peso = 2;
      } else if (respuesta === "no_responde") {
        peso = 3;
      }
    } else if (type.id === 6) {
      if (respuesta === "Verdadero") {
        peso = 1;
      } else if (respuesta === "Falso") {
        peso = 2;
      } else if (respuesta === "no_responde") {
        peso = 3;
      }
    } else if ([9, 8].includes(type.id)) {
      respuesta = removeUnderscoreAndHyphen(respuesta);
    } else if (type.id === 33) {
      let obj = selectedMatrixAnswers;
      let [key, value] = respuesta_v.split('|').map(str => str.trim());

      if (obj.hasOwnProperty(key)) {
        // Si existe, sustituimos el valor
        obj[key] = value;
      } else {
        // Si no existe, agregamos la clave con el nuevo valor
        obj[key] = value;
      }
      console.log(obj);
      respuesta = obj;
    }

    // TODO maybe check for validations?

    if (!respuesta || respuesta?.length === 0) {
      return;
    }

    if (isOnline) {
      console.log({ section, item, form });
      // just send the request, no need to handle the response
      req.post(
        `${API_URL}${publicPollType ? 'enlaces' : 'encuestas'}/respuestas/participantes/${idRespuesta}/update`,
        {
          respuesta,
          peso,
          ...(type.id === 4 ? { comentario: respuesta } : {}),
          ...(type.id === 33 ? { sub_respuesta: respuesta } : {}),
        }
      );
      // TODO send a request to clear the answer of each dependent questions
    } else {
      performMutation({
        endpoint: `${API_URL}encuestas/respuestas/participantes/${idRespuesta}/update`,
        payload: {
          respuesta,
          peso,
          ...(type.id === 4 ? { comentario: respuesta } : {}),
        },
        offlineFn: () => {
          updatePoll(encuesta.id, (k) => {
            const itemCopy = { ...k }
            const sectionIndex = itemCopy.secciones.findIndex(x =>
              x.id ===
              section.id);
            const itemIndex = itemCopy.secciones[sectionIndex].items.findIndex(x => x.id === item.id);
            itemCopy.secciones[sectionIndex].items[itemIndex].respuestas_participante[0] = {
              ...itemCopy.secciones[sectionIndex].items[itemIndex].respuestas_participante[0],
              respuesta,
              peso,
              ...(type.id === 4 ? { comentario: respuesta } : {}),
              ...(type.id === 33 ? { sub_respuesta: respuesta } : {}),
            }
            return itemCopy;
          });
        },
        keysToRevalidate: ['offline-polls']
      });
    }
  };

  const type = item.tipo_pregunta;
  const fieldName = `field_${item.id}`;

  let answerField = <span>Rendering answer field...</span>;

  switch (type.id) {
    case 1: // selección única
    case 2: // selección múltiple
      const options = respuestas
        .filter((r) => r.activo)
        .map((r) => ({
          label: r.respuesta,
          value: r.respuesta,
        }));

      const value =
        typeof selectedAnswers[fieldName] === "string"
          ? selectedAnswers[fieldName].split("|")
          : selectedAnswers[fieldName];
      answerField =
        type.id === 2 ? (
          <MultiSelect
            id={fieldName}
            key={fieldName}
            name={fieldName}
            value={
              !value || !Array.isArray(value)
                ? null
                : value.map((x) => ({
                  label: x?.label || x,
                  value: x?.value || x,
                }))
            }
            isMulti
            required={item.requerido}
            isDisabled={!canEdit}
            onChange={(e) => {
              handleChange(
                {
                  type,
                  target: {
                    name: fieldName,
                    value: e.map((x) => x.label).join("|"),
                    required: item.requerido,
                  },
                },
                item.id,
                type.id
              );
            }}
            onBlur={() =>
              handleBlur({
                pregunta: item.pregunta,
                section,
                respuesta: selectedAnswers[fieldName],
              })
            }
            allowSelectAll
            options={options}
          />
        ) : (
          <MultiSelect
            id={fieldName}
            name={fieldName}
            key={fieldName}
            defaultValue={
              (selectedAnswers[fieldName]?.label || "").includes("null") ||
                selectedAnswers[fieldName]?.label === null
                ? null
                : selectedAnswers[fieldName]
            }
            className={`${errors[item.id]?.required ? "border-danger" : ""}`}
            required={item.requerido}
            isDisabled={!canEdit}
            onBlur={() =>
              handleBlur({
                section,
                respuesta: selectedAnswers[fieldName],
                pregunta: item.pregunta,
              })
            }
            onChange={(e) =>
              handleChange(
                {
                  type,
                  target: {
                    name: fieldName,
                    value: e,
                    required: item.requerido,
                  },
                },
                item.id,
                item.pregunta,
                section,
                value
              )
            }
            options={options}
          />
        );
      break;
    case 3: // fecha
      const selected = selectedAnswers[fieldName]
        ? isDateInFormat(selectedAnswers[fieldName]) === "dd/mm/yyyy"
          ? new Date(
            convertDateFormatToYYYYMMDD(selectedAnswers[fieldName]) + " 00:00"
          )
          : new Date(selectedAnswers[fieldName] + " 00:00")
        : null;
      answerField = (
        <CustomDatePicker
          selected={selected}
          id={fieldName}
          key={fieldName}
          name={fieldName}
          className={`${errors[item.id]?.required ? "border-danger" : ""}`}
          required={item.requerido}
          disabled={!canEdit}
          onChange={(date) => {
            const resultDate = convertDateFormatToDDMMYYYY(convertDate(date));
            handleChange(
              {
                type,
                target: {
                  name: fieldName,
                  value: resultDate,
                  required: item.requerido,
                },
              },
              item.id,
              item.pregunta
            );
            const idRespuesta = item.respuestas_participante[0].id;
            req.post(
              `${API_URL}${publicPollType ? 'enlaces' : 'encuestas'}/respuestas/participantes/${idRespuesta}/update`,
              {
                respuesta: resultDate,
              }
            );
          }}
        />
      );

      break;
    case 5: // si o no
      answerField = (
        <select
          id={fieldName}
          key={fieldName}
          name={fieldName}
          value={selectedAnswers[fieldName] ?? ""}
          className={`${errors[item.id]?.required ? "border-danger" : ""}`}
          required={item.requerido}
          disabled={!canEdit}
          onBlur={() => handleBlur({
            pregunta: item.pregunta,
            section,
            respuesta: selectedAnswers[fieldName]
          })}
          onChange={(e) =>
            handleChange({ ...e, type }, item.id, item.pregunta, e.target.peso)
          }
        >
          <option value="">Selecciona</option>
          <option value="Si">Si</option>
          <option value="No">No</option>
          <option value="no_responde">No responde</option>
        </select>
      );
      break;
    case 6: // verdadero o falso
      answerField = (
        <select
          id={fieldName}
          key={fieldName}
          name={fieldName}
          value={selectedAnswers[fieldName] ?? ""}
          className={`${errors[item.id]?.required ? "border-danger" : ""}`}
          disabled={!canEdit}
          required={item.requerido}
          onBlur={() => handleBlur({
            pregunta: item.pregunta,
            section,
            respuesta: selectedAnswers[fieldName]
          })}
          onChange={(e) =>
            handleChange({ ...e, type }, item.id, item.pregunta, e.target.peso)
          }
        >
          <option value="">Selecciona</option>
          <option value="Verdadero">Verdadero</option>
          <option value="Falso">Falso</option>
          <option value="no_responde">No responde</option>
        </select>
      );
      break;
    case 7: // número
      answerField = (
        <input
          type="number"
          id={fieldName}
          key={fieldName}
          name={fieldName}
          value={selectedAnswers[fieldName] ?? ""}
          className={`${errors[item.id]?.required ? "border-danger" : ""}`}
          required={item.requerido}
          disabled={!canEdit}
          onBlur={() => handleBlur({
            pregunta: item.pregunta,
            section,
            respuesta: selectedAnswers[fieldName]
          })}
          onChange={(e) => handleChange({ ...e, type }, item.id, item.pregunta)}
        ></input>
      );
      break;
    case 9:
      answerField = (
        <PatternFormat
          key={fieldName}
          id={fieldName}
          name={fieldName}
          format="###-#######-#"
          required={item.requerido}
          className={`${errors[item.id]?.required ? "border-danger" : ""}`}
          mask="_"
          value={selectedAnswers[fieldName]}
          onBlur={() =>
            handleBlur({
              section,
              respuesta: selectedAnswers[fieldName],
              pregunta: item.pregunta,
            })
          }
          onChange={(e) => handleChange({ ...e, type }, item.id, item.pregunta)}
        />
      );
      break;

    case 8:
      answerField = (
        <PatternFormat
          key={fieldName}
          id={fieldName}
          name={fieldName}
          format="###-###-####"
          required={item.requerido}
          className={`${errors[item.id]?.required ? "border-danger" : ""}`}
          mask="_"
          value={selectedAnswers[fieldName]}
          onBlur={() =>
            handleBlur({
              section,
              respuesta: selectedAnswers[fieldName],
              pregunta: item.pregunta,
            })
          }
          onChange={(e) => handleChange({ ...e, type }, item.id, item.pregunta)}
        />
      );
      break;

    case 32: //archivo
      answerField = (
        <>
          <input
            type="file"
            multiple
            accept={VALID_FILE_TYPES.join(",")}
            className={`${errors[item.id]?.required ? "border-danger" : ""}`}
            required={item.requerido}
            disabled={!canEdit}
            onBlur={handleBlur}
            onChange={handleFilesChange}
            style={{ marginBottom: '20px' }}
          />

          <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {fileData.map((doc) => (
              <div
                key={doc.id}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  background: '#f8f9fa',
                  padding: '10px',
                  borderRadius: '4px'
                }}
              >
                <a
                  href={doc.documento_url}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    margin: 0,
                    color: '#007bff',
                    fontWeight: 'bold',
                    textDecoration: 'none',
                    cursor: 'pointer'
                  }}
                >
                  {doc.descripcion ?? doc.documento_name}
                </a>

                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDeleteServerFile(doc.id)}
                  style={{
                    position: 'relative',
                    backgroundColor: "white",
                    borderRadius: "50%",
                    border: "2px solid red",
                    padding: "8px"
                  }}
                >
                  <span
                    style={{
                      display: 'block',
                      width: "10px",
                      height: "2px",
                      backgroundColor: "red",
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  ></span>
                </Button>
              </div>
            ))}
          </div>
        </>
      )

      break;
    case 33: // Matriz 
      const matrizQuestions = item.sub_pregunta
        .split(' | ') // Divide la cadena en un array de preguntas
        .map(respuesta => ({ respuesta, activo: true }));

      const matrizAnswers = item.respuestas[0]?.sub_respuesta
        ? item.respuestas[0].sub_respuesta
          .split(' | ') // Divide la cadena en un array de respuestas
          .map(respuesta => ({ respuesta, activo: true }))
        : [];
      answerField = (
        <>
          <input
            type="text"
            key={fieldName}
            id={fieldName}
            hidden
            name={fieldName}
            value={selectedAnswers[fieldName] ?? ""}
            className={`${errors[item.id]?.required ? "border-danger" : "matrix"}`}
            required={item.requerido}
            disabled={!canEdit}
            onBlur={handleBlur}
            onChange={(e) => handleChange({ ...e, type }, item.id, item.pregunta)}
          ></input>
          <table className="matrizTable">
            <thead>
              <tr><th></th>
                {matrizAnswers.map((rr, aIndex) => (
                  <th>{rr.respuesta.replace(/_/g, " ")}</th>
                ))}</tr>
            </thead>
            <tbody>
              {matrizQuestions.map((p, qIndex) => (
                <tr key={qIndex}>
                  <td><label>{p.respuesta.replace(/_/g, " ")}</label></td>
                  {matrizAnswers.map((r, aIndex) => (
                    <td>
                      <label key={aIndex}>
                        <input
                          type="radio"
                          name={p.respuesta} // Agrupación de radio buttons por pregunta
                          value={r.respuesta}
                          checked={
                            selectedMatrixAnswers?.[p.respuesta] === r.respuesta ||
                            JSON.parse(selectedAnswers?.[fieldName] || '{}')[p.respuesta] === r.respuesta
                          } onChange={(e) => {
                            setSelectedMatrixAnswers(prev => ({
                              ...prev,
                              [p.respuesta]: e.target.value, // Actualiza el valor seleccionado para la pregunta actual
                            }));
                            handleBlur({
                              pregunta: fieldName,
                              section,
                              respuesta: `${p.respuesta} | ${r.respuesta}`,
                            })
                          }}
                          disabled={!canEdit}
                        />
                      </label>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      );
      break;
    case 4: // texto
    default:
      answerField = (
        <input
          type="text"
          key={fieldName}
          id={fieldName}
          name={fieldName}
          value={selectedAnswers[fieldName] ?? ""}
          className={`${errors[item.id]?.required ? "border-danger" : ""}`}
          required={item.requerido}
          disabled={!canEdit}
          onBlur={() => handleBlur({
            pregunta: item.pregunta,
            section,
            respuesta: selectedAnswers[fieldName]
          })}
          onChange={(e) => handleChange({ ...e, type }, item.id, item.pregunta)}
        ></input>
      );
      break;
  }

  // its for display a multiselect always when tipo_dato is multiple
  if (type.tipo_dato === 'multiple' || type.tipo_dato === 'unico') {
    const options = respuestas
      .filter((r) => r.activo)
      .map((r) => ({
        label: r.respuesta,
        value: r.respuesta,
      }));

    const value = typeof selectedAnswers[fieldName] === 'string' ?
      selectedAnswers[fieldName].split('|') :
      selectedAnswers[fieldName]
    answerField =
      type.tipo_dato === 'multiple' ? (
        <MultiSelect
          id={fieldName}
          key={fieldName}
          name={fieldName}
          value={(!value || !Array.isArray(value)) ? null : value.map(x => ({ label: x?.label || x, value: x?.value || x }))}
          isMulti
          required={item.requerido}
          isDisabled={!canEdit}
          onChange={(e) => {
            handleChange(
              {
                type,
                target: {
                  name: fieldName,
                  value: e.map(x => x.label).join('|'),
                  required: item.requerido,
                },
              },
              item.id,
              type.id
            )
          }}
          onBlur={() => handleBlur({
            pregunta: item.pregunta,
            section,
            respuesta: selectedAnswers[fieldName]
          })}
          allowSelectAll
          options={options}
        />
      ) : (
        <MultiSelect
          id={fieldName}
          name={fieldName}
          key={fieldName}
          defaultValue={((selectedAnswers[fieldName]?.label || '').includes('null') || selectedAnswers[fieldName]?.label === null) ? null : selectedAnswers[fieldName]}
          className={`${errors[item.id]?.required ? 'border-danger' : ''}`}
          required={item.requerido}
          isDisabled={!canEdit}
          onBlur={() => handleBlur({ section, respuesta: selectedAnswers[fieldName], pregunta: item.pregunta, })}
          onChange={(e) =>
            handleChange(
              {
                type,
                target: {
                  name: fieldName,
                  value: e,
                  required: item.requerido,
                },
              },
              item.id,
              item.pregunta,
              section,
              value
            )
          }
          options={options}
        />
      );
  }

  return (
    <div className={section.tipo == "general" ? "form-group" : "form-group segregado"}>
      <label>{item.pregunta}</label>
      {answerField}
      {errors[item.id]?.required && (
        <p className="text-danger small">Este campo es requerido.</p>
      )}
    </div>
  );
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function convertDate(inputFormat) {
  if (isValidDate(inputFormat)) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [
      pad(d.getFullYear()),
      pad(d.getMonth() + 1),
      ("0" + d.getDate()).slice(-2),
    ].join("/");
  } else {
    return inputFormat;
  }
}

export default PollQuestion;
