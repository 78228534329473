export function PollTitle({ participant, pollName, form }) {
  return (
    <div className='row'>
      <div className='col-lg-6'>
        <p>
          {`Participante: ${participant?.nombres} ${participant?.apellidos} ID: ${participant?.codigo}`}
        </p>
       
      </div>
      

      <div className='col-lg-6 text-end'>
      <p>{`Post evaluación — ${pollName}`}</p>
        <img
          alt={form.logo_name}
          src={form.logo_url}
          style={{
            maxWidth: "300px", 
            height: "auto",    
            background: "#dadada",
            borderRadius: "4px",
            padding: "4px",
          }}
        />
      </div>
    </div>
  );
}

export default PollTitle;
