import React, { Component } from "react";
import { Button, Col, Row, Form, Container, Nav } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import MaterialTable from "material-table";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { GET, req } from "../utils/request.js";
import { withRouter } from "react-router-dom";
import Alerts from "../common/Alerts";
import FS from "../components/FieldStructure";
import IconButton from "@material-ui/core/IconButton";
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import $ from "jquery";
import Audits from '../components/DynamicAudits';
import { API_URL } from "../utils/config";
import answerService from '../services/answers.js';
import axios from "axios";
import Utils from "../utils/config";

export const PROGRAMA_CAPACITACION_TECNICA_VOCACIONAL = 'programaCapacitacionTecnicaVocacional';
export const PROGRAMA_EMPRENDIMIENTO = 'programaEmprendimiento';
export const INDIRECT_FORMS = 'indirect_forms1';
export const ENCUESTA_POST_TEST = 'ENCUESTA_POST_TEST';
export const HIDDEN_FORMS = 'HIDDEN_FORMS';
export const SIN_DISCAPACIDAD = 'SIN_DISCAPACIDAD';
export const REPORTE_LABORAL = 'REPORTE_LABORAL_ENTRENA';
export const BENEFICIARIO_OFFLINE = 'BENEFICIARIO_OFFLINE';
export const BENEFICIARIO_ENCUESTA_EN_LINEA = 'BENEFICIARIO_ENCUESTA_EN_LINEA';

const reporteLaboralSectionsData = [
  { label: 'Nuevo empleo', key: 'nuevo_empleo' },
  { label: 'Mejor Empleo', key: 'mejor_empleo' },
  { label: 'Nuevo Emprendimiento', key: 'nuevo_emprendimiento' },
  { label: 'Emprendimiento Mejorado', key: 'emprendimiento_mejorado' },
  { label: 'Indicador de Emprendimiento', key: 'indicador_emprendimiento' },
]
const defaultReporteLaboralSecciones = reporteLaboralSectionsData.reduce((acc, curr) => ({ ...acc, [curr.key]: [] }), {});

class Configurations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      entryForm: [],
      exitForm: [],
      laboralForm: [],
      postTestForm: '',
      programaCapacitacion: '',
      programaEmprendimiento: '',
      questionsLaboralForm: [],
      questionsLaboralFormSelected: [],
      questionsLaboralFormSelectedReport14: [],
      fieldresponses: [],
      isAddDisabled: true,
      forms: [],
      formfields: [],
      dependence_type: "",
      programas: [],
      auditType: "config",
      showAudit: false,
      indirectForms: [],
      availableFormsToChoose: [],
      encuestaPostTest: [],
      hideForms: [],
      updatingEncuestaPostTest: false,
      updatingHiddenForm: false,
      updatingReporteLaboral: false,
      configuracionSinDiscapacidadPreguntas: [],
      configuracionSinDiscapacidadAnswers: [],
      reporteLaboralQuestions: [],
      reporteLaboralSecciones: defaultReporteLaboralSecciones,
      configuracionReporteLaboralAnswers: [],
      participantes: []
    };

    this.addDependence = this.addDependence.bind(this);
    this.desactivateRow = this.desactivateRow.bind(this);
    this.desactivateRowReport14 = this.desactivateRowReport14.bind(this);
    this.closeAudit = this.closeAudit.bind(this);
    this.getAuditColumn = this.getAuditColumn.bind(this);
    this.fetchAuditData = this.fetchAuditData.bind(this);
  }
  desactivateRow(data) {
    const newValue = this.state.questionsLaboralFormSelected.value.filter((field) => (field.questionId !== data.questionId || field.answerId !== data.answerId));

    this.setState({
      questionsLaboralFormSelected: { ...this.state.questionsLaboralFormSelected, value: newValue },
    });
  }
  desactivateRowReport14(data) {
    const newValue = this.state.questionsLaboralFormSelectedReport14.value.filter((field) => (field.questionId !== data.questionId || field.answerId !== data.answerId));

    this.setState({
      questionsLaboralFormSelectedReport14: { ...this.state.questionsLaboralFormSelectedReport14, value: newValue },
    });
  }

  showAudit(id) {
    this.fetchAuditData();
    this.setState({
      showAudit: true
    })
  }
  closeAudit() {
    this.setState({
      showAudit: false
    })
  }

  fetchSinDiscapacidadAnswers(questionId) {
    req.get(`${API_URL}formularios/respuestas`, {
      params: { formulario_item: questionId, ordenar: 'respuesta,asc', paginacion: 30 }
    }).then(response => {
      const answers = response.data.data || [];

      this.setState({ configuracionSinDiscapacidadAnswers: answers })
    })
  }

  fetchSinDiscapacidadQuestions(formId) {
    req.get(`${API_URL}formularios/items`, {
      params: { formulario: formId, estado: 'activos', paginacion: 999999 }
    }).then(response => {
      const preguntas = response.data.data || [];


      this.setState({ configuracionSinDiscapacidadPreguntas: preguntas })
    })
  }

  fetchReporteLaboralQuestions(formId) {
    req.get(`${API_URL}formularios/items`, {
      params: { formulario: formId, estado: 'activos', paginacion: 999999 }
    }).then(response => {
      const preguntas = response.data.data || [];


      this.setState({ reporteLaboralQuestions: preguntas })
    })
  }

  getAuditColumn(defaultColumns) {
    return [{
      title: 'key',
      field: 'key'
    }, ...defaultColumns];
  }
  fetchAuditData() {
    const API = Utils.API;
    let auth = JSON.parse(localStorage.getItem("user"));
    let access_token = auth.access_token;
    this.setState({ auditModalLoading: true })
    axios
      .get(API + 'config', {
        headers: { Authorization: "Bearer " + access_token },
      }).then(async (res) => {
        const configs = res.data.data || res.data || [];
        this.setState({ show: true, configs });
        console.log('fetchAuditData usuarios')
        GET(this, "usuarios");

        const configsWithAudits = await Promise.all(configs.map(async config => {
          const response = await axios.get(`${API}config/${config.id}/audit`, {
            headers: { Authorization: "Bearer " + access_token },
          });
          const audits = response.data.data;
          return audits.map(x => ({ ...x, key: config.key }));
        }));


        this.setState({ auditModalDataTable: configsWithAudits.filter(x => x.length).flat(1) })
        this.setState({ auditModalLoading: false })
      });
  }

  componentDidMount() {
    $(document).ready(function () {
      $(".nav-link").removeClass("active");
      $(".nav-item.administracion .nav-link").addClass("active");
      $(".dropdown-item").removeClass("active");
      $(".dropdown-item.organizaciones").addClass("active");
    });
    setTimeout(() => {
      this.setState({ show: true });
    }, 300);
    req.get(`${API_URL}config`, { params: { paginacion: 999999 } }).then((res) => {
      const forms = res.data.data ?? [];

      const beneficiarioOffline = forms.find(form => form.key === BENEFICIARIO_OFFLINE);
      const beneficiarioEncuestaEnLinea = forms.find(form => form.key === BENEFICIARIO_ENCUESTA_EN_LINEA);

      const entryForm = forms.find(form => form.key === 'entryForm');
      const exitForm = forms.find(form => form.key === 'exitForm');
      const laboralForm = forms.find(form => form.key === 'laboralForm');
      if (laboralForm || laboralForm?.length > 0) {
        let id = laboralForm.value || laboralForm[0].value;
        req.get(`${API_URL}formularios/items`, {
          params: { formulario: id, estado: 'activos', paginacion: 999999 }
        }).then(response => {
          this.setState({ formfields: response.data.data.sort((a, b) => a.indice - b.indice) ?? [] });
        });
      }
      // const postTestForm = forms.find(form => form.key === 'postTestForm');

      // programas
      const programaCapacitacion = forms.find(form => form.key === PROGRAMA_CAPACITACION_TECNICA_VOCACIONAL);
      const programaEmprendimiento = forms.find(form => form.key === PROGRAMA_EMPRENDIMIENTO);
      const laboralForm_Validations = forms.filter(form => form.key.startsWith('laboralForm_Validations'));
      if (laboralForm_Validations?.length > 0) {
        laboralForm_Validations[0].value = JSON.parse(laboralForm_Validations[0].value);
      } else {
        laboralForm_Validations.push({ value: [] });
      }
      const laboralForm_Validations_Report14 = forms.filter(form => form.key.startsWith('laboralForm_Validations_Report14'));
      if (laboralForm_Validations_Report14?.length > 0) {
        laboralForm_Validations_Report14[0].value = JSON.parse(laboralForm_Validations_Report14[0].value);
      } else {
        laboralForm_Validations_Report14.push({ value: [] });
      }

      const existenceIndirectForms = forms.find(form => form.key === INDIRECT_FORMS);
      const existenceEncuestaPostTest = forms.find(form => form.key === ENCUESTA_POST_TEST);
      const existenceHiddenForm = forms.find(form => form.key === HIDDEN_FORMS);
      const existenceSinDiscapacidadForm = forms.find(form => form.key === SIN_DISCAPACIDAD);
      const existencereporteLaboral = forms.find(form => form.key === REPORTE_LABORAL);
      const reporteLaboralSeccionesJson = existencereporteLaboral?.value ? JSON.parse(existencereporteLaboral.value) : {};

      this.setState({
        isLoaded: true,
        entryForm,
        exitForm,
        laboralForm,
        updatingBeneficiarioOffline: beneficiarioOffline,
        [BENEFICIARIO_OFFLINE]: parseInt(beneficiarioOffline?.value),

        updatingBeneficiarioEncuestaEnLinea: beneficiarioEncuestaEnLinea,
        [BENEFICIARIO_ENCUESTA_EN_LINEA]: parseInt(beneficiarioEncuestaEnLinea?.value),
        programaCapacitacion,
        programaEmprendimiento,
        laboralForm_Validations,
        laboralForm_Validations_Report14,
        questionsLaboralFormSelected: laboralForm_Validations[0],
        questionsLaboralFormSelectedReport14: laboralForm_Validations_Report14[0],
        updatingIndirectForms: existenceIndirectForms?.id,
        updatingEncuestaPostTest: existenceEncuestaPostTest?.id,
        updatingHiddenForm: existenceHiddenForm?.id,
        updatingSinDiscapacidad: existenceSinDiscapacidadForm?.id,
        updatingReporteLaboral: existencereporteLaboral?.id,
        indirectForms: existenceIndirectForms ? JSON.parse(existenceIndirectForms.value) : [],
        encuestaPostTest: existenceEncuestaPostTest ? JSON.parse(existenceEncuestaPostTest.value) : [],
        hideForms: existenceHiddenForm ? JSON.parse(existenceHiddenForm.value) : [],
        sinDiscapacidadForms: existenceSinDiscapacidadForm ? JSON.parse(existenceSinDiscapacidadForm.value) : [],
        reporteLaboralSecciones: existencereporteLaboral ?
          Object.entries({ ...defaultReporteLaboralSecciones, ...reporteLaboralSeccionesJson }).reduce((acc, [key, value]) => {
            const newValue = value.map(x => ({
              ...x,
              formulary: { id: x.formulary.id, name: x.formulary.nombre || x.formulary.name },
              question: { id: x.question.id, name: x.question.question || x.question.name },
            }))
            return { ...acc, [key]: newValue }
          }, {})
          : defaultReporteLaboralSecciones,
      });
    });
    req.get(`${API_URL}formularios`, { params: { estado: 'activos', paginacion: 999999 } }).then(response => {
      this.setState({
        forms: response.data.data ?? [],
        availableFormsToChoose: response.data.data ?? []
      });
    });
    req.get(`${API_URL}selectores/programas`).then(response => {
      this.setState({ programas: response.data.data ?? [] });
    });

    req.get(`${API_URL}selectores/participantes`).then(response => {
      this.setState({ participantes: response.data.data ?? [] });
    });

    this.fetchAuditData();
  }

  // TODO re-do this method
  addDependence(values) {
    if (!values.questionsLaboralForm_formfield) {
      return false;
    }
    if (!values.questionsLaboralForm_response) {
      return false;
    }

    const answerId = values.questionsLaboralForm_response;
    const answerName = this.getSelectedAnswerName(answerId);
    const questionId = values.questionsLaboralForm_formfield;
    const questionName = this.state.formfields.find(question => question.id === parseInt(questionId))?.pregunta;

    const isAlreadyInList = this.state.questionsLaboralFormSelected.value.some(value => value.answerId === answerId && value.questionId === questionId);
    if (isAlreadyInList) {
      return false;
    }

    const newValue = [
      ...this.state.questionsLaboralFormSelected.value,
      {
        answerId,
        answerName,
        questionId,
        questionName
      }
    ];
    this.setState({
      questionsLaboralFormSelected: {
        key: "laboralForm_Validations",
        value: newValue
      },
    });
  }
  addDependenceReporte14(values) {
    if (!values.questionsLaboralFormReport14_formfield) {
      return false;
    }
    if (!values.questionsLaboralFormReport14_response) {
      return false;
    }

    const answerId = values.questionsLaboralFormReport14_response;
    const answerName = this.getSelectedAnswerName(answerId);
    const questionId = values.questionsLaboralFormReport14_formfield;
    const questionName = this.state.formfields.find(question => question.id === parseInt(questionId))?.pregunta;

    const isAlreadyInList = this.state.questionsLaboralFormSelectedReport14.value.some(value => value.answerId === answerId && value.questionId === questionId);
    if (isAlreadyInList) {
      return false;
    }

    const newValue = [
      ...this.state.questionsLaboralFormSelectedReport14.value,
      {
        answerId,
        answerName,
        questionId,
        questionName
      }
    ];
    console.log(newValue);
    this.setState({
      questionsLaboralFormSelectedReport14: {
        key: "laboralForm_Validations_Report14",
        value: newValue
      },
    });
  }
  selectDependence_formfield(value) {
    answerService.query(value, 999999, 'activos').then(response => {
      this.setState({ fieldresponses: response.data.data ?? [] });
    });
    const item = this.state.formfields.find(item => item.id === parseInt(value));
    this.setState({
      dependence_type: item.tipo_pregunta.nombre.toLowerCase(),
      isAddDisabled: true,
    });
  }
  selectDependence_formfield_sin_discapacidad(value) {
    answerService.query(value, 999999, 'activos').then(response => {
      this.setState({ configuracionSinDiscapacidadAnswers: response.data.data ?? [] });
    });

    const item = this.state.configuracionSinDiscapacidadPreguntas
      .find(item => item.id === parseInt(value));

    console.log({ item, configuracionSinDiscapacidadAnswers: this.state.configuracionSinDiscapacidadAnswers, value })
    this.setState({
      dependence_type: item.tipo_pregunta.nombre.toLowerCase(),
      isAddDisabled: true,
    });
  }
  selectDependence_formfield_reporte_laboral(value) {
    answerService.query(value, 999999, 'activos').then(response => {
      this.setState({ configuracionReporteLaboralAnswers: response.data.data ?? [] });
    });

    const item = this.state.reporteLaboralQuestions
      .find(item => item.id === parseInt(value));

    // console.log({ item, configuracionSinDiscapacidadAnswers: this.state.configuracionSinDiscapacidadAnswers, value })
    this.setState({
      dependence_type: item.tipo_pregunta.nombre.toLowerCase(),
      isAddDisabled: true,
    });
  }



  getSelectedAnswerName(answerValue) {
    const { fieldresponses, dependence_type } = this.state;
    switch (dependence_type) {
      case 'selección única':
      case 'selección múltiple':
        return fieldresponses.find(answer => answer.id === answerValue)?.respuesta;
      case 'si o no':
        return answerValue === 1 ? 'Si' : 'No';
      case 'verdadero o falso':
        return answerValue === 1 ? 'Verdadero' : 'Falso';;
      default:
        return answerValue;
    }
  }

  renderAnswerField(formik) {
    const { fieldresponses, dependence_type } = this.state;

    switch (dependence_type) {
      case 'selección única':
      case 'selección múltiple':

        return (
          <Field ref={this.answerDependence} name="questionsLaboralForm_response" as="select"
            onChange={e => {
              formik.handleChange(e);
              this.setState({ isAddDisabled: !e.target.value });
            }}>
            <option value=""> Selecciona </option>
            {fieldresponses
              .map((type) => (
                <option key={type.id} value={type.id}>
                  {` ${type.respuesta} `}
                </option>
              ))}
          </Field>
        );
      case 'si o no':
        return (
          <Field ref={this.answerDependence} name="questionsLaboralForm_response" as="select"
            onChange={e => {
              formik.handleChange(e);
              this.setState({ isAddDisabled: !e.target.value });
            }}>
            <option value=""> Selecciona </option>
            <option value="1"> Si </option>
            <option value="0"> No </option>
          </Field>
        );
      case 'verdadero o falso':
        return (
          <Field ref={this.answerDependence} name="questionsLaboralForm_response" as="select"
            onChange={e => {
              formik.handleChange(e);
              this.setState({ isAddDisabled: !e.target.value });
            }}>
            <option value=""> Selecciona </option>
            <option value="1"> Verdadero </option>
            <option value="0"> Falso </option>
          </Field>
        );
      default:
        return (<Field ref={this.answerDependence} type="text" name="questionsLaboralForm_response" onChange={e => {
          formik.handleChange(e);
          this.setState({ isAddDisabled: !e.target.value })
        }
        } />);
    }
  }
  renderAnswerFieldReport14(formik) {
    const { fieldresponses, dependence_type } = this.state;

    switch (dependence_type) {
      case 'selección única':
      case 'selección múltiple':

        return (
          <Field ref={this.answerDependence} name="questionsLaboralFormReport14_response" as="select"
            onChange={e => {
              formik.handleChange(e);
              this.setState({ isAddDisabled: !e.target.value });
            }}>
            <option value=""> Selecciona </option>
            {fieldresponses
              .map((type) => (
                <option key={type.id} value={type.id}>
                  {` ${type.respuesta} `}
                </option>
              ))}
          </Field>
        );
      case 'si o no':
        return (
          <Field ref={this.answerDependence} name="questionsLaboralFormReport14_response" as="select"
            onChange={e => {
              formik.handleChange(e);
              this.setState({ isAddDisabled: !e.target.value });
            }}>
            <option value=""> Selecciona </option>
            <option value="1"> Si </option>
            <option value="0"> No </option>
          </Field>
        );
      case 'verdadero o falso':
        return (
          <Field ref={this.answerDependence} name="questionsLaboralFormReport14_response" as="select"
            onChange={e => {
              formik.handleChange(e);
              this.setState({ isAddDisabled: !e.target.value });
            }}>
            <option value=""> Selecciona </option>
            <option value="1"> Verdadero </option>
            <option value="0"> Falso </option>
          </Field>
        );
      default:
        return (<Field ref={this.answerDependence} type="text" name="questionsLaboralFormReport14_response" onChange={e => {
          formik.handleChange(e);
          this.setState({ isAddDisabled: !e.target.value })
        }
        } />);
    }
  }

  renderAnswerFieldSinDiscapacidad(formik) {
    const { configuracionSinDiscapacidadAnswers, dependence_type } = this.state;

    switch (dependence_type) {
      case 'selección única':
      case 'selección múltiple':

        return (
          <Field ref={this.answerDependence} name="ConfiguracionSinDiscapacidad_respuesta" as="select"
            onChange={e => {
              formik.handleChange(e);
              this.setState({ isAddDisabled: !e.target.value });
            }}>
            <option value=""> Selecciona </option>
            {configuracionSinDiscapacidadAnswers
              .map((type) => (
                <option key={type.id} value={type.id}>
                  {` ${type.respuesta} `}
                </option>
              ))}
          </Field>
        );
      case 'si o no':
        return (
          <Field ref={this.answerDependence} name="ConfiguracionSinDiscapacidad_respuesta" as="select"
            onChange={e => {
              formik.handleChange(e);
              this.setState({ isAddDisabled: !e.target.value });
            }}>
            <option value=""> Selecciona </option>
            <option value="Si"> Si </option>
            <option value="No"> No </option>
          </Field>
        );
      case 'verdadero o falso':
        return (
          <Field ref={this.answerDependence} name="ConfiguracionSinDiscapacidad_respuesta" as="select"
            onChange={e => {
              formik.handleChange(e);
              this.setState({ isAddDisabled: !e.target.value });
            }}>
            <option value=""> Selecciona </option>
            <option value="Verdadero"> Verdadero </option>
            <option value="Falso"> Falso </option>
          </Field>
        );
      default:
        return (<Field ref={this.answerDependence} type="text" name="ConfiguracionSinDiscapacidad_respuesta" onChange={e => {
          formik.handleChange(e);
          this.setState({ isAddDisabled: !e.target.value })
        }
        } />);
    }
  }

  renderAnswerFieldReporteLaboral(formik) {
    const { configuracionReporteLaboralAnswers, dependence_type } = this.state;

    switch (dependence_type) {
      case 'selección única':
      case 'selección múltiple':

        return (
          <Field ref={this.answerDependence} name="ConfiguracionReporteLaboral_respuesta" as="select"
            onChange={e => {
              formik.handleChange(e);
              this.setState({ isAddDisabled: !e.target.value });
            }}>
            <option value=""> Selecciona </option>
            {configuracionReporteLaboralAnswers
              .map((type) => (
                <option key={type.id} value={type.id}>
                  {` ${type.respuesta} `}
                </option>
              ))}
          </Field>
        );
      case 'si o no':
        return (
          <Field ref={this.answerDependence} name="ConfiguracionReporteLaboral_respuesta" as="select"
            onChange={e => {
              formik.handleChange(e);
              this.setState({ isAddDisabled: !e.target.value });
            }}>
            <option value=""> Selecciona </option>
            <option value="Si"> Si </option>
            <option value="No"> No </option>
          </Field>
        );
      case 'verdadero o falso':
        return (
          <Field ref={this.answerDependence} name="ConfiguracionReporteLaboral_respuesta" as="select"
            onChange={e => {
              formik.handleChange(e);
              this.setState({ isAddDisabled: !e.target.value });
            }}>
            <option value=""> Selecciona </option>
            <option value="Verdadero"> Verdadero </option>
            <option value="Falso"> Falso </option>
          </Field>
        );
      default:
        return (<Field ref={this.answerDependence} type="text" name="ConfiguracionReporteLaboral_respuesta" onChange={e => {
          formik.handleChange(e);
          this.setState({ isAddDisabled: !e.target.value })
        }
        } />);
    }
  }

  addNewIndirectForm(id) {
    const newItem = this.state.forms.find(x => x.id === parseInt(id));
    this.setState({
      indirectForms: [...this.state.indirectForms, newItem]
    })
  }

  addNewHiddenForm(id) {
    const newItem = this.state.forms.find(x => x.id === parseInt(id));
    this.setState({
      hideForms: [...this.state.hideForms, newItem]
    })
  }

  removeIndirectForm(id) {
    this.setState({
      indirectForms:
        this.state.indirectForms.filter(x => x.id !== parseInt(id)),
    })
  }

  removeHidenForm(id) {
    this.setState({
      hideForms:
        this.state.hideForms.filter(x => x.id !== parseInt(id)),
    })
  }

  addNewEncuestaPostTest({ formulario, programa }) {
    this.setState({
      encuestaPostTest: [...this.state.encuestaPostTest, { formulario, programa }]
    })
  }

  removeNewEncuestaPostTest(formularioId) {
    this.setState({
      encuestaPostTest:
        this.state.encuestaPostTest.filter(x => x.formulario.id !== parseInt(formularioId)),
    })
  }

  removeSinDiscapacidad(id) {
    this.setState({
      configuracionSinDiscapacidad:
        this.state.configuracionSinDiscapacidad.filter(x => x.id !== id),
    })
  }

  removeReportelaboral(seccion, id) {
    const newSeccion = this.state.reporteLaboralSecciones[seccion].filter(x => x.id !== id)
    this.setState({
      reporteLaboralSecciones:
        { ...this.state.reporteLaboralSecciones, [seccion]: newSeccion }
    })
  }

  render() {
    const validationSchema = Yup.object().shape({
      entryFrom: Yup.string().required("El formulario de Entrada es un campo requerido.")
    });
    const { entryForm, exitForm, laboralForm_Validations, laboralForm_Validations_Report14,
      questionsLaboralFormSelected, questionsLaboralFormSelectedReport14, laboralForm,
      forms, alertType, alertMessage, alertShow, formfields, isAddDisabled, programas,
      programaCapacitacion, programaEmprendimiento, participantes
    } = this.state;
    const initialValues = {
      reporteLaboralFormSelected: null,
      reporteLaboralPreguntaSelected: null,
      reporteLaboralSeccionSelected: null,
      entryForm: entryForm?.value ?? "",
      exitForm: exitForm?.value ?? "",
      laboralForm: laboralForm?.value ?? "",
      // postTestForm: postTestForm?.value ?? '',
      [PROGRAMA_CAPACITACION_TECNICA_VOCACIONAL]: programaCapacitacion?.value ?? '',
      [PROGRAMA_EMPRENDIMIENTO]: programaEmprendimiento?.value ?? '',
      questionsLaboralForm_formfield: null,
      questionsLaboralForm_response: null,
      ConfiguracionSinDiscapacidad: [],
      [BENEFICIARIO_OFFLINE]: this.state[BENEFICIARIO_OFFLINE],
      [BENEFICIARIO_ENCUESTA_EN_LINEA]: this.state[BENEFICIARIO_ENCUESTA_EN_LINEA]
    }
    const dataAudit = [entryForm,
      exitForm,
      laboralForm,
      // postTestForm,
      laboralForm_Validations];


    const availableFormsToChoose = this.state.forms.filter(form => !this.state.indirectForms.some(x => x.id === form.id))
    const availableHiddenFormsToChoose = this.state.forms.filter(form => !this.state.hideForms.some(x => x.id === form.id))

    return (
      <Container>
        {this.state.showAudit && (
          <Audits
            close={this.closeAudit}
            show={this.state.showAudit}
            data={this.state.auditModalDataTable}
            getColumns={this.getAuditColumn}
          />
        )}

        {/* {this.state.showAuditAnswers && <Audits close={this.closeAudit} show={this.state.showAudit} tipo={this.state.auditType} id={this.state.questionsLaboralFormSelected._id} />
        } */}
        <div className="titleButtons">
          <Nav className="justify-content-end">
            <Alerts
              these={this}
              type={alertType}
              message={alertMessage}
              show={alertShow}
            />
            <div class="nav-item">
              <p class="nav-link" onClick={() => this.showAudit()}><span>Historico</span></p>
            </div>
          </Nav>
        </div>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
          <div className="mainBox">
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                console.log({ values });
                if (this.state.updatingReporteLaboral) {

                  req.post(`${API_URL}config/${this.state.updatingReporteLaboral}/update`,
                    {
                      key: REPORTE_LABORAL,
                      value: JSON.stringify(this.state.reporteLaboralSecciones)
                    });
                } else {

                  req.post(`${API_URL}config/store`,
                    {
                      key: REPORTE_LABORAL,
                      value: JSON.stringify(this.state.reporteLaboralSecciones)
                    });


                }

                console.log('klk: ', this.state.updatingBeneficiarioOffline);
                if (this.state.updatingBeneficiarioOffline) {
                  req.post(`${API_URL}config/${this.state.updatingBeneficiarioOffline.id}/update`,
                    {
                      key: BENEFICIARIO_OFFLINE,
                      value: values[BENEFICIARIO_OFFLINE]
                    });
                } else {
                  req.post(`${API_URL}config/store`,
                    {
                      key: BENEFICIARIO_OFFLINE,
                      value: values[BENEFICIARIO_OFFLINE]
                    });
                }

                if (this.state.updatingBeneficiarioEncuestaEnLinea) {
                  req.post(`${API_URL}config/${this.state.updatingBeneficiarioEncuestaEnLinea.id}/update`,
                    {
                      key: BENEFICIARIO_ENCUESTA_EN_LINEA,
                      value: values[BENEFICIARIO_ENCUESTA_EN_LINEA]
                    });
                } else {
                  req.post(`${API_URL}config/store`,
                    {
                      key: BENEFICIARIO_ENCUESTA_EN_LINEA,
                      value: values[BENEFICIARIO_ENCUESTA_EN_LINEA]
                    });
                }

                if (this.state.updatingIndirectForms) {

                  req.post(`${API_URL}config/${this.state.updatingIndirectForms}/update`,
                    {
                      key: INDIRECT_FORMS,
                      value: JSON.stringify(this.state.indirectForms)
                    });
                } else {

                  req.post(`${API_URL}config/store`,
                    {
                      key: INDIRECT_FORMS,
                      value: JSON.stringify(this.state.indirectForms)
                    });

                }

                if (this.state.updatingHiddenForm) {

                  req.post(`${API_URL}config/${this.state.updatingHiddenForm}/update`,
                    {
                      key: HIDDEN_FORMS,
                      value: JSON.stringify(this.state.hideForms)
                    });
                } else {

                  req.post(`${API_URL}config/store`,
                    {
                      key: HIDDEN_FORMS,
                      value: JSON.stringify(this.state.hideForms)
                    });

                }
                if (this.state.updatingSinDiscapacidad) {

                  req.post(`${API_URL}config/${this.state.updatingSinDiscapacidad}/update`,
                    {
                      key: SIN_DISCAPACIDAD,
                      value: JSON.stringify(this.state.sinDiscapacidadForms)
                    });
                } else {

                  req.post(`${API_URL}config/store`,
                    {
                      key: SIN_DISCAPACIDAD,
                      value: JSON.stringify(this.state.sinDiscapacidadForms)
                    });

                }

                if (this.state.updatingEncuestaPostTest) {

                  req.post(`${API_URL}config/${this.state.updatingEncuestaPostTest}/update`,
                    {
                      key: ENCUESTA_POST_TEST,
                      value: JSON.stringify(this.state.encuestaPostTest)
                    });
                } else {

                  req.post(`${API_URL}config/store`,
                    {
                      key: ENCUESTA_POST_TEST,
                      value: JSON.stringify(this.state.encuestaPostTest)
                    });

                }


                if (!entryForm) {
                  req.post(`${API_URL}config/store`,
                    {
                      key: 'entryForm',
                      value: values.entryForm
                    });
                } else {
                  req.post(`${API_URL}config/${entryForm.id}/update`,
                    {
                      key: "entryForm",
                      value: values.entryForm
                    }
                  );
                }
                if (!exitForm) {
                  req.post(`${API_URL}config/store`,
                    {
                      key: "exitForm",
                      value: values.exitForm
                    }
                  );
                } else {
                  req.post(`${API_URL}config/${exitForm.id}/update`,
                    {
                      key: "exitForm",
                      value: values.exitForm
                    }
                  );
                }
                if (!laboralForm) {
                  req.post(`${API_URL}config/store`,
                    {
                      key: "laboralForm",
                      value: values.laboralForm
                    }
                  );
                } else {
                  req.post(`${API_URL}config/${laboralForm.id}/update`,
                    {
                      key: "laboralForm",
                      value: values.laboralForm
                    }
                  );
                }
                // if (!postTestForm) {
                //   req.post(`${API_URL}config/store`,
                //     {
                //       key: 'postTestForm',
                //       value: values.postTestForm
                //     }
                //   );
                // } else {
                //   req.post(`${API_URL}config/${postTestForm.id}/update`,
                //     {
                //       key: 'postTestForm',
                //       value: values.postTestForm
                //     }
                //   );
                // }

                // programas
                if (!programaCapacitacion) {
                  req.post(`${API_URL}config/store`, {
                    key: PROGRAMA_CAPACITACION_TECNICA_VOCACIONAL,
                    value: values[PROGRAMA_CAPACITACION_TECNICA_VOCACIONAL]
                  });
                } else {
                  req.post(`${API_URL}config/${programaCapacitacion.id}/update`, {
                    key: PROGRAMA_CAPACITACION_TECNICA_VOCACIONAL,
                    value: values[PROGRAMA_CAPACITACION_TECNICA_VOCACIONAL]
                  });
                }
                if (!programaEmprendimiento) {
                  req.post(`${API_URL}config/store`, {
                    key: PROGRAMA_EMPRENDIMIENTO,
                    value: values[PROGRAMA_EMPRENDIMIENTO]
                  });
                } else {
                  req.post(`${API_URL}config/${programaEmprendimiento.id}/update`, {
                    key: PROGRAMA_EMPRENDIMIENTO,
                    value: values[PROGRAMA_EMPRENDIMIENTO]
                  });
                }

                const validationsId = laboralForm_Validations[0].id;
                if (validationsId !== undefined) {
                  req.post(`${API_URL}config/${laboralForm_Validations[0].id}/update`,
                    {
                      key: "laboralForm_Validations",
                      value: JSON.stringify(questionsLaboralFormSelected.value),
                    }
                  );
                } else {
                  req.post(`${API_URL}config/store`,
                    {
                      key: "laboralForm_Validations",
                      value: JSON.stringify(questionsLaboralFormSelected.value),
                    }
                  ).then(response => {
                    this.setState({
                      questionsLaboralFormSelected: response.data.data
                    });
                  });
                }

                const validationsIdReport14 = laboralForm_Validations_Report14[0].id;
                if (validationsIdReport14 !== undefined) {
                  req.post(`${API_URL}config/${laboralForm_Validations_Report14[0].id}/update`,
                    {
                      key: "laboralForm_Validations_Report14",
                      value: JSON.stringify(questionsLaboralFormSelectedReport14.value),
                    }
                  );
                } else {
                  req.post(`${API_URL}config/store`,
                    {
                      key: "laboralForm_Validations_Report14",
                      value: JSON.stringify(questionsLaboralFormSelectedReport14.value),
                    }
                  ).then(response => {
                    this.setState({
                      questionsLaboralFormSelectedReport14: response.data.data
                    });
                  });
                }

                $(window).scrollTop(0);
                this.setState({
                  alertShow: true,
                  alertMessage: "Configuración Actualizada",
                  alertType: "success"
                });
                setSubmitting(false);
              }}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>

                  <div className="subBox">
                    <h3>Configuración de Encuestas</h3>
                    <Row>
                      <FS
                        label="Encuesta de Inscripción*"
                        name="entryForm"
                        errors={formik.errors}
                        col={[4]}
                      >
                        <Field name="entryForm" as="select">
                          <option value=""> Selecciona </option>
                          {forms.map((p) => (
                            <option key={p.id} value={p.id}> {p.nombre}</option>
                          ))}
                        </Field>
                      </FS>
                      <FS
                        label="Encuesta de Salida*"
                        name="exitForm"
                        errors={formik.errors}
                        col={[4]}
                      >
                        <Field name="exitForm" as="select">
                          <option value=""> Selecciona </option>
                          {forms.map((p) => (
                            <option key={p.id} value={p.id}> {p.nombre}</option>
                          ))}
                        </Field>
                      </FS>
                      <FS
                        label="Encuesta Laboral*"
                        name="laboralForm"
                        errors={formik.errors}
                        col={[4]}
                      >
                        <Field name="laboralForm" as="select" onChange={e => {
                          formik.handleChange(e);
                          req.get(`${API_URL}formularios/items`, {
                            params: { formulario: e.target.value, estado: 'activos', paginacion: 999999 }
                          }).then(response => {
                            this.setState({ formfields: response.data.data ?? [] });
                          });
                        }}>
                          <option value=""> Selecciona </option>
                          {forms.map((p) => (
                            <option key={p.id} value={p.id}> {p.nombre}</option>
                          ))}
                        </Field>
                      </FS>
                      {/* <FS
                        label="Encuesta de Post Test*"
                        name="postTestForm"
                        errors={formik.errors}
                        col={[4]}
                      >
                        <Field name="postTestForm" as="select">
                          <option value=""> Selecciona </option>
                          {forms.map((p) => (
                            <option key={p.id} value={p.id}> {p.nombre}</option>
                          ))}
                        </Field>
                      </FS> */}

                    </Row>
                  </div>


                  <br />
                  <div className="subBox mt-2">
                    <h3>Configuración de programas</h3>
                    <Row>
                      <FS label='Programa de capacitación técnica*'
                        name={PROGRAMA_CAPACITACION_TECNICA_VOCACIONAL}
                        errors={formik.errors}
                        col={[4]}>
                        <Field name={PROGRAMA_CAPACITACION_TECNICA_VOCACIONAL} as='select'>
                          <option value=''>Selecciona</option>
                          {programas.map(programa => (<option key={`programa_${programa.id}`} value={programa.id}>{programa.programa}</option>))}
                        </Field>
                      </FS>

                      <FS label='Programa de emprendimiento*'
                        name={PROGRAMA_EMPRENDIMIENTO}
                        errors={formik.errors}
                        col={[4]}>
                        <Field name={PROGRAMA_EMPRENDIMIENTO} as='select'>
                          <option value=''>Selecciona</option>
                          {programas.map(programa => (<option key={`programa_${programa.id}`} value={programa.id}>{programa.programa}</option>))}
                        </Field>
                      </FS>
                    </Row>
                  </div>
                  <div className="subBox mt-2">
                    <h3>Beneficiario Offline</h3>
                    <Row>
                      <FS
                        label=''
                        name={BENEFICIARIO_OFFLINE}
                        errors={formik.errors}
                        col={[4]}>
                        <Field name={BENEFICIARIO_OFFLINE} as='select'>
                          <option value=''>Selecciona</option>
                          {participantes.map(participante => (<option key={`participante${participante.id}`} value={participante.id}>{participante.participante}</option>))}
                        </Field>
                      </FS>
                    </Row>
                  </div>

                  <div className="subBox mt-2">
                    <h3>Beneficiario de Encuesta en Linea</h3>
                    <Row>
                      <FS
                        label=''
                        name={BENEFICIARIO_ENCUESTA_EN_LINEA}
                        errors={formik.errors}
                        col={[4]}>
                        <Field name={BENEFICIARIO_ENCUESTA_EN_LINEA} as='select'>
                          <option value=''>Selecciona</option>
                          {participantes.map(participante => (<option key={`participante${participante.id}`} value={participante.id}>{participante.participante}</option>))}
                        </Field>
                      </FS>
                    </Row>
                  </div>
                  <br />
                  <h3>Formularios Beneficiarios Indirectos</h3>
                  <div class="nav-item">
                    {/* <p class="nav-link" onClick={() => this.showAuditAnswers()}><span>Historico</span></p> */}
                  </div>
                  <div className="subBox">
                    <div className="form-group">
                      <Row>
                        <FS
                          label="Formulario"
                          name="FormularioBeneficiariosIndirectos"
                          errors={formik.errors}
                          col={[3]}
                        >
                          <Field name="FormularioBeneficiariosIndirectos"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            ref={this.question} as="select">
                            <option value=""> Selecciona </option>
                            {availableFormsToChoose.map(form => (
                              <option key={form.id} value={form.id}>
                                {` ${form.nombre} `}
                              </option>
                            ))}
                          </Field>
                        </FS>
                        <Col lg={3}>
                          <Button
                            type='button'
                            variant="primary "
                            className="secondaryButton"
                            disabled={!formik.values.FormularioBeneficiariosIndirectos}
                            onClick={() => {
                              this.addNewIndirectForm(formik.values.FormularioBeneficiariosIndirectos)
                              formik.setFieldValue('FormularioBeneficiariosIndirectos', '')
                            }}
                          >
                            Añadir
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <MaterialTable
                      title=""
                      columns={[
                        {
                          title: 'ID', field: 'id'
                        },
                        {
                          title: 'Formulario', field: 'nombre'
                        },
                        {
                          title: "Acciones",
                          searchable: true,
                          cellStyle: {
                            minWidth: 200,
                            maxWidth: 200
                          },
                          field: "actions",
                          render: (rowData) => (
                            <IconButton
                              onClick={() => this.removeIndirectForm(rowData.id)}
                            >
                              <DoNotDisturbOnOutlinedIcon />
                            </IconButton>
                          )
                        }
                      ]}
                      localization={{
                        toolbar: {
                          searchTooltip: 'Buscar',
                          searchPlaceholder: 'Buscar'
                        },
                        pagination: {
                          previousTooltip: 'Página anterior',
                          nextTooltip: 'Página siguiente',
                          firstTooltip: 'Primera página',
                          lastTooltip: 'Última página'
                        },
                        body: {
                          emptyDataSourceMessage: 'No hay registros que mostrar',
                        }
                      }}
                      data={this.state.indirectForms}
                      options={{
                        search: false,
                        pagination: false
                      }}
                    />
                  </div>
                  <br />
                  <h3>Validación de Encuestas Post Test</h3>
                  <div class="nav-item">
                    {/* <p class="nav-link" onClick={() => this.showAuditAnswers()}><span>Historico</span></p> */}
                  </div>
                  <div className="subBox">
                    <div className="form-group">
                      <Row>
                        <FS
                          label="Formulario"
                          name="ValidacionPostTestFormulario"
                          errors={formik.errors}
                          col={[3]}
                        >
                          <Field
                            name="ValidacionPostTestFormulario"
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldValue('ValidacionPostTestPrograma', '')
                            }}
                            ref={this.question} as="select">
                            <option value=""> Selecciona </option>
                            {this.state.forms
                              .map(form => (
                                <option key={form.id} value={form.id}>
                                  {` ${form.nombre} `}
                                </option>
                              ))}
                          </Field>
                        </FS>
                        <FS
                          label="Programa"
                          name="ValidacionPostTestPrograma"
                          errors={formik.errors}
                          col={[3]}
                        >
                          <Field
                            name="ValidacionPostTestPrograma"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            ref={this.question} as="select">
                            <option value=""> Selecciona </option>
                            {this.state.programas
                              .filter(x => {
                                if (this.state.encuestaPostTest.some(y => y.formulario.id === parseInt(formik.values.ValidacionPostTestFormulario) && y.programa.id === x.id)) {
                                  return false
                                }
                                return true
                              })
                              .map(form => (
                                <option key={form.id} value={form.id}>
                                  {` ${form.programa} `}
                                </option>
                              ))}
                          </Field>
                        </FS>
                        <Col lg={3}>
                          <Button
                            type='button'
                            variant="primary "
                            className="secondaryButton"
                            disabled={!formik.values.ValidacionPostTestPrograma || !formik.values.ValidacionPostTestFormulario}
                            onClick={() => {
                              const formulario = this.state.forms.find(x => x.id === parseInt(formik.values.ValidacionPostTestFormulario));
                              const programa = this.state.programas.find(x => x.id === parseInt(formik.values.ValidacionPostTestPrograma));
                              this.addNewEncuestaPostTest({
                                formulario,
                                programa
                              })
                              formik.setFieldValue('ValidacionPostTestPrograma', '')
                              formik.setFieldValue('ValidacionPostTestFormulario', '')
                            }}
                          >
                            Añadir
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <MaterialTable
                      title=""
                      columns={[
                        {
                          title: 'Fomulario', field: 'formulario.nombre'
                        },
                        {
                          title: 'Programa', field: 'programa.programa'
                        },
                        {
                          title: "Acciones",
                          searchable: true,
                          cellStyle: {
                            minWidth: 200,
                            maxWidth: 200
                          },
                          field: "actions",
                          render: (rowData) => (
                            <IconButton
                              onClick={() => this.removeNewEncuestaPostTest(rowData.formulario.id)}
                            >
                              <DoNotDisturbOnOutlinedIcon />
                            </IconButton>
                          )
                        }
                      ]}
                      localization={{
                        toolbar: {
                          searchTooltip: 'Buscar',
                          searchPlaceholder: 'Buscar'
                        },
                        pagination: {
                          previousTooltip: 'Página anterior',
                          nextTooltip: 'Página siguiente',
                          firstTooltip: 'Primera página',
                          lastTooltip: 'Última página'
                        },
                        body: {
                          emptyDataSourceMessage: 'No hay registros que mostrar',
                        }
                      }}
                      data={this.state.encuestaPostTest}
                      options={{
                        search: false,
                        pagination: false
                      }}
                    />
                  </div>

                  {/* <h3>Configuración de respuesta sin discapacidad</h3>
                  <div class="nav-item">
      
                  </div>
                  <div className="subBox">
                    <div className="form-group">
                      <Row>
                        <FS
                          label="Formulario"
                          name="ConfiguracionSinDiscapacidad_formulario"
                          errors={formik.errors}
                          col={[3]}
                        >
                          <Field
                            name="ConfiguracionSinDiscapacidad_formulario"
                            onChange={(e) => {
                              formik.handleChange(e);
                              this.fetchSinDiscapacidadQuestions(e.currentTarget.value)
                            }}
                            ref={this.question}
                            as="select">
                            <option value=""> Selecciona </option>
                            {this.state.forms
                              .map(form => (
                                <option key={form.id} value={form.id}>
                                  {` ${form.nombre} `}
                                </option>
                              ))}
                          </Field>
                        </FS>
                        <FS
                          label="Pregunta"
                          name="ConfiguracionSinDiscapacidad_pregunta"
                          errors={formik.errors}
                          col={[3]}
                        >
                          <Field name="ConfiguracionSinDiscapacidad_pregunta"
                            onChange={(e) => {
                              formik.handleChange(e);
                              // this.fetchSinDiscapacidadAnswers(e.currentTarget.value);
                              this.selectDependence_formfield_sin_discapacidad(e.target.value);
                            }}
                            ref={this.question} as="select">
                            <option value=""> Selecciona </option>
                            {
                              this.state.configuracionSinDiscapacidadPreguntas.sort(function (a, b) {
                                return a.pregunta.split('.')[0] - b.pregunta.split('.')[0];
                              }).map((item) => (
                                <option key={item.id} value={item.id}>
                                  {` ${item.pregunta} `}
                                </option>
                              ))
                            }
                          </Field>
                        </FS>
                        <FS
                          label="Respuesta"
                          name="ConfiguracionSinDiscapacidad_respuesta"
                          errors={formik.errors}
                          col={[3]}
                        >
                          {this.renderAnswerFieldSinDiscapacidad(formik)}
                        </FS>
                        <Col lg={3}>
                          <Button
                            type='button'
                            variant="primary "
                            className="secondaryButton"
                            disabled={!formik.values.ConfiguracionSinDiscapacidad_formulario ||
                              !formik.values.ConfiguracionSinDiscapacidad_pregunta ||
                              !formik.values.ConfiguracionSinDiscapacidad_respuesta
                            }
                            onClick={() => {
                              const ConfiguracionSinDiscapacidad = [
                                ...formik.values.ConfiguracionSinDiscapacidad,
                                {
                                  id: Math.random().toString(36).substring(2, 10) + new Date().getTime().toString(36),
                                  formulary: this.state.forms.find(form => form.id === parseInt(formik.values.ConfiguracionSinDiscapacidad_formulario)),
                                  question: this.state.configuracionSinDiscapacidadPreguntas.find(form => form.id === parseInt(formik.values.ConfiguracionSinDiscapacidad_pregunta)),
                                  answer: (this.state.configuracionSinDiscapacidadAnswers.find(form => form.id === parseInt(formik.values.ConfiguracionSinDiscapacidad_respuesta)))?.respuesta || formik.values.ConfiguracionSinDiscapacidad_respuesta,
                                }
                              ];

                              formik.setFieldValue('ConfiguracionSinDiscapacidad_formulario', '')
                              formik.setFieldValue('ConfiguracionSinDiscapacidad_pregunta', '')
                              formik.setFieldValue('ConfiguracionSinDiscapacidad_respuesta', '')

                              this.setState({
                                configuracionSinDiscapacidadPreguntas: [],
                                configuracionSinDiscapacidadAnswers: []
                              })

                              this.setState({
                                sinDiscapacidadForms: ConfiguracionSinDiscapacidad
                              })

                            }}
                          >
                            Añadir
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <MaterialTable
                      title=""
                      columns={[
                        {
                          title: 'Fomulario', field: 'formulary.nombre'
                        },
                        {
                          title: 'Pregunta', field: 'question.pregunta'
                        },
                        {
                          title: 'Answer', field: 'answer'
                        },
                        {
                          title: "Acciones",
                          searchable: true,
                          cellStyle: {
                            minWidth: 200,
                            maxWidth: 200
                          },
                          field: "actions",
                          render: (rowData) => (
                            <IconButton
                              onClick={() => this.removeSinDiscapacidad(rowData.id)}
                            >
                              <DoNotDisturbOnOutlinedIcon />
                            </IconButton>
                          )
                        }
                      ]}
                      localization={{
                        toolbar: {
                          searchTooltip: 'Buscar',
                          searchPlaceholder: 'Buscar'
                        },
                        pagination: {
                          previousTooltip: 'Página anterior',
                          nextTooltip: 'Página siguiente',
                          firstTooltip: 'Primera página',
                          lastTooltip: 'Última página'
                        },
                        body: {
                          emptyDataSourceMessage: 'No hay registros que mostrar',
                        }
                      }}
                      data={this.state.sinDiscapacidadForms}
                      options={{
                        search: false,
                        pagination: false
                      }}
                    />
                  </div> */}


                  <br />
                  <h3>No Aplica a ningún formulario</h3>
                  <div class="nav-item">
                    {/* <p class="nav-link" onClick={() => this.showAuditAnswers()}><span>Historico</span></p> */}
                  </div>
                  <div className="subBox">
                    <div className="form-group">
                      <Row>
                        <FS
                          label="Formulario"
                          name="HiddenForm"
                          errors={formik.errors}
                          col={[3]}
                        >
                          <Field name="HiddenForm"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            ref={this.question} as="select">
                            <option value=""> Selecciona </option>
                            {availableHiddenFormsToChoose.map(form => (
                              <option key={form.id} value={form.id}>
                                {` ${form.nombre} `}
                              </option>
                            ))}
                          </Field>
                        </FS>
                        <Col lg={3}>
                          <Button
                            type='button'
                            variant="primary "
                            className="secondaryButton"
                            disabled={!formik.values.HiddenForm}
                            onClick={() => {
                              this.addNewHiddenForm(formik.values.HiddenForm)
                              formik.setFieldValue('HiddenForm', '')
                            }}
                          >
                            Añadir
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <MaterialTable
                      title=""
                      columns={[
                        {
                          title: 'ID', field: 'id'
                        },
                        {
                          title: 'Formulario', field: 'nombre'
                        },
                        {
                          title: "Acciones",
                          searchable: true,
                          cellStyle: {
                            minWidth: 200,
                            maxWidth: 200
                          },
                          field: "actions",
                          render: (rowData) => (
                            <IconButton
                              onClick={() => this.removeHidenForm(rowData.id)}
                            >
                              <DoNotDisturbOnOutlinedIcon />
                            </IconButton>
                          )
                        }
                      ]}
                      localization={{
                        toolbar: {
                          searchTooltip: 'Buscar',
                          searchPlaceholder: 'Buscar'
                        },
                        pagination: {
                          previousTooltip: 'Página anterior',
                          nextTooltip: 'Página siguiente',
                          firstTooltip: 'Primera página',
                          lastTooltip: 'Última página'
                        },
                        body: {
                          emptyDataSourceMessage: 'No hay registros que mostrar',
                        }
                      }}
                      data={this.state.hideForms}
                      options={{
                        search: false,
                        pagination: false
                      }}
                    />
                  </div>

                  <br />
                  <h3>Configuración de validaciones para Encuesta Laboral</h3>
                  <div class="nav-item">
                    {/* <p class="nav-link" onClick={() => this.showAuditAnswers()}><span>Historico</span></p> */}
                  </div>
                  <div className="subBox">
                    <div className="form-group">
                      <Row>
                        <FS
                          label="Pregunta"
                          name="questionsLaboralForm_formfield"
                          errors={formik.errors}
                          col={[3]}
                        >
                          <Field name="questionsLaboralForm_formfield"
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldValue('questionsLaboralForm_response', '');
                              this.selectDependence_formfield(e.target.value)
                            }}
                            ref={this.question} as="select">
                            <option value=""> Selecciona </option>
                            {
                              formfields.sort(function (a, b) {
                                return a.pregunta.split('.')[0] - b.pregunta.split('.')[0];
                              }).map((item) => (
                                <option key={item.id} value={item.id}>
                                  {` ${item.pregunta} `}
                                </option>
                              ))
                            }
                          </Field>
                        </FS>
                        <FS
                          label="Respuesta"
                          name="questionsLaboralForm_response"
                          errors={formik.errors}
                          col={[3]}
                        >
                          {this.renderAnswerField(formik)}
                        </FS>
                        <Col lg={3}>
                          <Button
                            type='button'
                            variant="primary "
                            className="secondaryButton"
                            disabled={isAddDisabled}
                            onClick={() => this.addDependence(formik.values)}
                          >
                            Añadir
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <MaterialTable
                      title=""
                      columns={[
                        {
                          title: 'Pregunta', field: 'questionName'
                        },
                        {
                          title: 'Respuesta', field: 'answerName'
                        },
                        {
                          title: "Acciones",
                          searchable: true,
                          cellStyle: {
                            minWidth: 200,
                            maxWidth: 200
                          },
                          field: "actions",
                          render: (rowData) => (
                            <IconButton
                              onClick={() => this.desactivateRow(rowData)}
                            >
                              <DoNotDisturbOnOutlinedIcon />
                            </IconButton>
                          )
                        }
                      ]}
                      localization={{
                        toolbar: {
                          searchTooltip: 'Buscar',
                          searchPlaceholder: 'Buscar'
                        },
                        pagination: {
                          previousTooltip: 'Página anterior',
                          nextTooltip: 'Página siguiente',
                          firstTooltip: 'Primera página',
                          lastTooltip: 'Última página'
                        },
                        body: {
                          emptyDataSourceMessage: 'No hay registros que mostrar',
                        }
                      }}
                      data={questionsLaboralFormSelected?.value}
                      options={{
                        search: false,
                        pagination: false
                      }}
                    />
                  </div>
                  <br />
                  <h3>Configuración de validaciones para Reporte Laboral 14. Capacitados en curso vocacional y sin empleos y/o emprendimiento
                  </h3>
                  <div class="nav-item">
                    {/* <p class="nav-link" onClick={() => this.showAuditAnswers()}><span>Historico</span></p> */}
                  </div>
                  <div className="subBox">
                    <div className="form-group">
                      <Row>
                        <FS
                          label="Pregunta"
                          name="questionsLaboralFormReport14_formfield"
                          errors={formik.errors}
                          col={[3]}
                        >
                          <Field name="questionsLaboralFormReport14_formfield"
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldValue('questionsLaboralFormReport14_response', '');
                              this.selectDependence_formfield(e.target.value)
                            }}
                            ref={this.question} as="select">
                            <option value=""> Selecciona </option>
                            {
                              formfields.sort(function (a, b) {
                                return a.pregunta.split('.')[0] - b.pregunta.split('.')[0];
                              }).map((item) => (
                                <option key={item.id} value={item.id}>
                                  {` ${item.pregunta} `}
                                </option>
                              ))
                            }
                          </Field>
                        </FS>
                        <FS
                          label="Respuesta"
                          name="questionsLaboralFormReport14_response"
                          errors={formik.errors}
                          col={[3]}
                        >
                          {this.renderAnswerFieldReport14(formik)}
                        </FS>
                        <Col lg={3}>
                          <Button
                            type='button'
                            variant="primary "
                            className="secondaryButton"
                            disabled={isAddDisabled}
                            onClick={() => this.addDependenceReporte14(formik.values)}
                          >
                            Añadir
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <MaterialTable
                      title=""
                      columns={[
                        {
                          title: 'Pregunta', field: 'questionName'
                        },
                        {
                          title: 'Respuesta', field: 'answerName'
                        },
                        {
                          title: "Acciones",
                          searchable: true,
                          cellStyle: {
                            minWidth: 200,
                            maxWidth: 200
                          },
                          field: "actions",
                          render: (rowData) => (
                            <IconButton
                              onClick={() => this.desactivateRowReport14(rowData)}
                            >
                              <DoNotDisturbOnOutlinedIcon />
                            </IconButton>
                          )
                        }
                      ]}
                      localization={{
                        header: {
                          actions: 'Acciones',
                        },
                        toolbar: {
                          searchTooltip: 'Buscar',
                          searchPlaceholder: 'Buscar'
                        },
                        pagination: {
                          previousTooltip: 'Página anterior',
                          nextTooltip: 'Página siguiente',
                          firstTooltip: 'Primera página',
                          lastTooltip: 'Última página'
                        },
                        body: {
                          emptyDataSourceMessage: 'No hay registros que mostrar',
                        }
                      }}
                      data={questionsLaboralFormSelectedReport14?.value}
                      options={{
                        search: false,
                        pagination: false
                      }}
                    />
                  </div>
                  <br />
                  <h3>Configuracion de Reporte Laboral 20 y 21</h3>
                  <div class="nav-item">
                    {/* <p class="nav-link" onClick={() => this.showAuditAnswers()}><span>Historico</span></p> */}
                  </div>
                  <div className="subBox">
                    <div className="form-group">
                      <Row>
                        <FS
                          label="Seccion"
                          name="reporteLaboralSeccionSelected"
                          errors={formik.errors}
                          col={[3]}
                        >
                          <Field name="reporteLaboralSeccionSelected"
                            onChange={(e) => {
                              formik.handleChange(e);

                            }}
                            ref={this.question}
                            as="select"
                          >
                            <option value=""> Selecciona </option>
                            {
                              reporteLaboralSectionsData.map(({ key, label }) => (
                                <option key={key} value={key}>
                                  {` ${label} `}
                                </option>
                              ))
                            }
                          </Field>
                        </FS>
                        <FS
                          label="Formulario"
                          name="reporteLaboralFormSelected"
                          errors={formik.errors}
                          col={[3]}
                        >
                          <Field
                            name="reporteLaboralFormSelected"
                            onChange={(e) => {
                              formik.handleChange(e);
                              this.fetchReporteLaboralQuestions(e.currentTarget.value);
                            }}
                            ref={this.question}
                            as="select">
                            <option value=""> Selecciona </option>
                            {this.state.forms
                              .map(form => (
                                <option key={form.id} value={form.id}>
                                  {` ${form.nombre} `}
                                </option>
                              ))}
                          </Field>
                        </FS>
                        <FS
                          label="Pregunta"
                          name="reporteLaboralPreguntaSelected"
                          errors={formik.errors}
                          col={[3]}
                        >
                          <Field name="reporteLaboralPreguntaSelected"
                            onChange={(e) => {
                              formik.handleChange(e);
                              this.selectDependence_formfield_reporte_laboral(e.target.value)

                            }}
                            ref={this.question} as="select">
                            <option value=""> Selecciona </option>
                            {
                              (this.state.reporteLaboralQuestions || []).sort(function (a, b) {
                                return a.pregunta.split('.')[0] - b.pregunta.split('.')[0];
                              }).map((item) => (
                                <option key={item.id} value={item.id}>
                                  {` ${item.pregunta} `}
                                </option>
                              ))
                            }
                          </Field>
                        </FS>
                        <FS
                          label="Respuesta"
                          name="ConfiguracionReporteLaboral_respuesta"
                          errors={formik.errors}
                          col={[3]}
                        >
                          {this.renderAnswerFieldReporteLaboral(formik)}
                        </FS>

                        <Col lg={3}>
                          <Button
                            type='button'
                            variant="primary "
                            className="secondaryButton"
                            disabled={!formik.values.reporteLaboralFormSelected ||
                              !formik.values.reporteLaboralPreguntaSelected || !formik.values.reporteLaboralSeccionSelected || !formik.values.ConfiguracionReporteLaboral_respuesta
                            }
                            onClick={() => {
                              const reporteLaboralSecciones = { ...this.state.reporteLaboralSecciones }
                              const formulary = this.state.forms.find(form => form.id === parseInt(formik.values.reporteLaboralFormSelected));
                              const question = this.state.reporteLaboralQuestions.find(form => form.id === parseInt(formik.values.reporteLaboralPreguntaSelected));
                              const answer = (this.state.configuracionReporteLaboralAnswers.find(form => form.id === parseInt(formik.values.ConfiguracionReporteLaboral_respuesta)))?.respuesta || formik.values.ConfiguracionReporteLaboral_respuesta
                              console.log({ answer, values: formik.values });
                              reporteLaboralSecciones[formik.values.reporteLaboralSeccionSelected] = [
                                ...reporteLaboralSecciones[formik.values.reporteLaboralSeccionSelected],
                                {
                                  id: Math.random().toString(36).substring(2, 10) + new Date().getTime().toString(36),
                                  formulary: { name: formulary.nombre, id: formulary.id },
                                  question: { name: question.pregunta, id: question.id },
                                  answer: { name: answer.nombre || answer, id: answer.id || answer },
                                  // formulary: this.state.forms.find(form => form.id === parseInt(formik.values.reporteLaboralFormSelected)),
                                  // question: this.state.reporteLaboralQuestions.find(form => form.id === parseInt(formik.values.reporteLaboralPreguntaSelected)),
                                }
                              ]

                              formik.setFieldValue('reporteLaboralFormSelected', '');
                              formik.setFieldValue('reporteLaboralPreguntaSelected', '');
                              formik.setFieldValue('reporteLaboralSeccionSelected', '');
                              formik.setFieldValue('ConfiguracionReporteLaboral_respuesta', '');

                              this.setState({
                                reporteLaboralQuestions: [],
                              })

                              this.setState({
                                reporteLaboralSecciones
                              })
                            }}
                          >
                            Añadir
                          </Button>
                        </Col>
                      </Row>
                    </div>

                    {reporteLaboralSectionsData.map(({ key, label }) => (
                      <div key={key}>
                        <h5>{label}</h5>
                        <MaterialTable
                          title=""
                          columns={[
                            {
                              title: 'Fomulario', field: 'formulary.name'
                            },
                            {
                              title: 'Pregunta', field: 'question.name'
                            },
                            {
                              title: 'Respuesta', field: 'answer.name'
                            },

                            {
                              title: "Acciones",
                              searchable: true,
                              cellStyle: {
                                minWidth: 200,
                                maxWidth: 200
                              },
                              field: "actions",
                              render: (rowData) => (
                                <IconButton
                                  onClick={() => this.removeReportelaboral(key, rowData.id)}
                                >
                                  <DoNotDisturbOnOutlinedIcon />
                                </IconButton>
                              )
                            }
                          ]}
                          localization={{
                            toolbar: {
                              searchTooltip: 'Buscar',
                              searchPlaceholder: 'Buscar'
                            },
                            pagination: {
                              previousTooltip: 'Página anterior',
                              nextTooltip: 'Página siguiente',
                              firstTooltip: 'Primera página',
                              lastTooltip: 'Última página'
                            },
                            body: {
                              emptyDataSourceMessage: 'No hay registros que mostrar',
                            }
                          }}
                          data={this.state.reporteLaboralSecciones[key]}
                          options={{
                            search: false,
                            pagination: false
                          }}
                        />
                      </div>
                    ))}
                  </div>


                  <Button
                    type="submit"
                    className="buttonSubmit"
                    variant="primary "
                  >
                    {formik.isSubmitting ? "Guardando..." : "Guardar"}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </CSSTransition>

      </Container>
    );
  }
}

export default withRouter(Configurations);
