import React, { createContext, useContext, useEffect, useState } from "react";
import { openDB } from "idb";

const DatabaseContext = createContext(null);

export const useDatabase = () => useContext(DatabaseContext);

export const DatabaseProvider = ({ children }) => {
    const [db, setDb] = useState(null);

    useEffect(() => {
        console.log('here 1')
        // Open the database on mount
        const initializeDB = async () => {
            console.log("here 2")
            const dbInstance = await openDB('MYLOCALDB', 1, {
                upgrade(db) {
                    console.log({ please: db.objectStoreNames })
                    if (!db.objectStoreNames.contains("pendingMutations")) {
                        db.createObjectStore('pendingMutations', { keyPath: 'id', autoIncrement: true });
                        console.log("duro")
                    }
                },
            });
            setDb(dbInstance);
        };

        initializeDB();

        // Optionally, close the database on unmount
        return () => {
            if (db) db.close();
        };
    }, []);

    return (
        <DatabaseContext.Provider value={db}>
            {children}
        </DatabaseContext.Provider>
    );
};
