import React, { Component } from 'react';
import { Container, Nav, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import Audits from '../components/Audits.js';
import { withRouter } from 'react-router-dom';
import Alerts from '../common/Alerts.js';
import { req } from '../utils/request.js';
import $ from 'jquery';
import { API_URL } from '../utils/config.js';

import { BeneficiaryForms } from './BeneficiaryForms.jsx';

import { useAsistenciaActividades, useConfig, useOrganizacion, useOrganizaciones, useParticipantes, useSelectoresFormularios, useSelectoresOrganizaciones } from '../App.js';

import { CircularProgress, Stack } from '@mui/material';

import BeneficiariesListTable from './BeneficiariesListTable.js';


export function paginateArray(data, pageSize, pageNumber) {
  // Calculate the start and end index for the current page
  const start = (pageNumber - 1) * pageSize;
  const end = start + pageSize;

  // Slice the array to get the current page items
  return data.slice(start, end);
}


function Wrapper(props) {


  const participantesResponse = useParticipantes();
  const { data: organizaciones } = useOrganizaciones();
  const { data: organizacion } = useOrganizacion();
  const { data: selectoresOrganizaciones } = useSelectoresOrganizaciones();
  const { data: asistenciaActividades } = useAsistenciaActividades();
  const { data: selectoresFormularios } = useSelectoresFormularios();
  const { data: config } = useConfig();
  // console.log({
  //   participantesResponse,
  //   organizaciones,
  //   organizacion,
  //   selectoresOrganizaciones,
  //   asistenciaActividades,
  //   selectoresFormularios,
  //   config
  // })

  if (!!participantesResponse && !!organizaciones && !!organizacion && !!selectoresOrganizaciones && !!asistenciaActividades && !!selectoresFormularios && !!config) {
    return <BeneficiariesList
      {...props}
      {...{
        participantesResponse,
        organizaciones,
        organizacion,
        selectoresOrganizaciones,
        asistenciaActividades,
        selectoresFormularios,
        config
      }}
    />
  }
}

class BeneficiariesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      entryForm: '',
      exitForm: '',
      laboralForm: '',
      laboralForm_Validations: [],
      activitiesattendances: [],
      alertShow: false,
      alertMessage: '',
      alertType: '',
      organizaciones: [],
      forms: [],
      popupIndirect: false,
      popupNoConfigured: false,
      popupNoConfiguredData: '',
      participants: [],
      auditType: 'participantes',
      showAudit: null,
      showFormsModal: false,
      formsModalParticipant: null,
    };

    this.show_PopUpIndirecto = this.show_PopUpIndirecto.bind(this);
    this.hide_PopUpIndirecto = this.hide_PopUpIndirecto.bind(this);

    this.show_PopUpNoConfigured = this.show_PopUpNoConfigured.bind(this);
    this.hide_PopUpNoConfigured = this.hide_PopUpNoConfigured.bind(this);

    this.action_RowOptions = this.action_RowOptions.bind(this);
    this.editRow = this.editRow.bind(this);
    this.desactivateRow = this.desactivateRow.bind(this);
    this.activateRow = this.activateRow.bind(this);
    this.closeAudit = this.closeAudit.bind(this);
  }
  show_PopUpIndirecto(data) {
    this.setState({ popupIndirect: true });
  }
  hide_PopUpIndirecto() {
    this.setState({ popupIndirect: false });
  }
  show_PopUpNoConfigured(text) {
    this.setState({ popupNoConfigured: true, popupNoConfiguredData: text });
  }
  hide_PopUpNoConfigured() {
    this.setState({ popupNoConfigured: false, popupNoConfiguredData: '' });
  }

  showAudit(id) {
    this.setState({
      showAudit: id,
    });
  }
  closeAudit() {
    this.setState({
      showAudit: null,
    });
  }
  desactivateRow(data) {
    data.activo = false;
    req.post(`${API_URL}participantes/${data.id}/toggle`).then(() => {
      this.props.history.push({
        pathname: '/beneficiarios',
        state: {
          alertShow: true,
          alertType: 'success',
          alertMessage: 'El beneficiario ha sido desactivado con éxito',
        },
      });
    });
  }
  activateRow(data) {
    data.activo = true;
    req.post(`${API_URL}participantes/${data.id}/toggle`).then(() => {
      this.props.history.push({
        pathname: '/beneficiarios',
        state: {
          alertShow: true,
          alertType: 'success',
          alertMessage: 'El beneficiario ha sido activado con éxito',
        },
      });
    });
  }
  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        alertShow: this.props.location.state.alertShow,
        alertMessage: this.props.location.state.alertMessage,
        alertType: this.props.location.state.alertType,
      });
    }

    $('.nav-link').removeClass('active');
    $('h1').children('span').remove();
    $('h1').children('em').remove();
    $(".nav-link[href='/beneficiarios']").addClass('active');
    setTimeout(() => {
      this.setState({ show: true });
    }, 200);


    const organizaciones = this.props.selectoresOrganizaciones;
    if (organizaciones && Array.isArray(organizaciones)) {
      this.setState({ organizaciones: organizaciones });
    }
    // req.get(`${API_URL}selectores/organizaciones`).then((response) => {
    //   this.setState({ organizaciones: response.data.data ?? [] });
    // });

    const asistenciaActividades = this.props.asistenciaActividades;
    if (asistenciaActividades && Array.isArray(asistenciaActividades)) {
      this.setState({ activitiesattendances: asistenciaActividades });
    }
    // req.get(`${API_URL}asistencia/actividades`).then((response) => {
    //   this.setState({ activitiesattendances: response.data.data ?? [] });
    // });


    const selectoresFormularios = this.props.selectoresFormularios;
    if (selectoresFormularios && Array.isArray(selectoresFormularios)) {
      this.setState({ forms: selectoresFormularios });
    }
    // req.get(`${API_URL}selectores/formularios`).then((response) => {
    //   this.setState({ forms: response.data.data ?? [] });
    // });

    const configs = this.props.config;

    if (configs && Array.isArray(configs)) {
      let entryForm = '';
      let exitForm = '';
      let laboralForm = '';
      let validations = [];
      configs.forEach((config) => {
        switch (config.key) {
          case 'laboralForm':
            laboralForm = config.value;
            break;
          case 'entryForm':
            entryForm = config.value;
            break;
          case 'exitForm':
            exitForm = config.value;
            break;
          default:
            // validations = JSON.parse(config.value);
            break;
        }
      });
      this.setState({
        entryForm,
        exitForm,
        laboralForm,
        laboralForm_Validations: validations,
      });
    }


    // req.get(`${API_URL}config`).then((response) => {

    // });
  }

  editRow(data) {
    this.props.history.push('/beneficiarios/' + data.id);
  }

  action_RowOptions(action, data) {
    switch (action) {
      case 'Llenar Inscripción a Participantes':
        if (data.data.colateral === false) {
          this.show_PopUpNoConfigured(
            'No es posible llenar una Encuesta de Inscripción a Participantes siendo un Participante Indirecto'
          );
        } else {
          window.location.replace(
            '/formularios/' + this.state.entryForm[0].value + '/' + data.data.id
          );
        }
        break;
      case 'Editar Inscripción a Participantes':
        if (
          this.state.entryForm[0] !== undefined &&
          this.state.entryForm[0].value !== null
        ) {
          window.location.replace(
            '/formularios/' +
            this.state.entryForm[0].value +
            '/' +
            data.data.id +
            '/' +
            data.data.entryFormAnswers
          );
        }
        break;
      case 'Llenar Encuesta de Salida':
        if (data.data.colateral === false) {
          this.show_PopUpNoConfigured(
            'No es posible llenar una Encuesta de Inscripción a Participantes siendo un Participante Indirecto'
          );
        } else {
          if (
            this.state.exitForm[0] !== undefined &&
            this.state.exitForm[0].value !== null
          ) {
            checkIfCanExitForm(
              this,
              data.data,
              this.state.activitiesattendances,
              this.state.participants
            );
          }
        }
        break;
      case 'Editar Encuesta de Salida':
        window.location.replace(
          '/formularios/' +
          this.state.exitForm[0].value +
          '/' +
          data.data.id +
          '/' +
          data.data.exitFormAnswers
        );
        break;
      case 'Llenar Encuesta Laboral':
        if (data.data.colateral === false) {
          this.show_PopUpNoConfigured(
            'No es posible llenar una Encuesta de Laboral sin tener una Encuesta de Inscripción a Participantes'
          );
        } else {
          if (
            this.state.laboralForm[0] !== undefined &&
            this.state.laboralForm[0].value !== null
          ) {
            checkIfCanLaboralForm(
              this,
              data.data,
              this.state.laboralForm_Validations,
              'crear',
              this.state.activitiesattendances
            );
          }
        }
        break;
      case 'Editar Encuesta Laboral':
        if (
          this.state.laboralForm[0] !== undefined &&
          this.state.laboralForm[0].value !== null
        ) {
          checkIfCanLaboralForm(
            this,
            data.data,
            this.state.laboralForm_Validations,
            'editar',
            this.state.activitiesattendances
          );
        }
        break;
      default:
        break;
    }
  }
  render() {
    const {
      alertMessage,
      alertShow,
      alertType,
      popupIndirect,
      forms,
      popupNoConfigured,
      popupNoConfiguredData,
    } = this.state;

    const auth = JSON.parse(localStorage.getItem('user')); // replace the load data with the hooks created on App.js

    return (
      <Container>
        {this.state.showAudit && (
          <Audits
            close={this.closeAudit}
            show={!!this.state.showAudit}
            tipo={this.state.auditType}
            id={this.state.showAudit}
          />
        )}
        <div className='titleButtons'>
          <Nav className='justify-content-end'>
            <Alerts
              these={this}
              type={alertType}
              message={alertMessage}
              show={alertShow}
            />
            <Nav.Item>
              <Link className='nav-link' to='/registro'>
                <span>Agregar nuevo beneficiario</span>
              </Link>
            </Nav.Item>
            {/* <span onClick={() => this.props.participantesResponse.refetch()}>refetch participantes</span> */}
            <Nav.Item>
              <Link className='nav-link' to='/actividades'>
                <span>Ver Actividades</span>
              </Link>
            </Nav.Item>
          </Nav>
        </div>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames='transitionPage'
        >
          <div className='mainBox beneficiaries_list'>

            {this.props.participantesResponse.data && !this.props.participantesResponse.isLoading ? (
              <BeneficiariesListTable
                data={(this.props?.participantesResponse?.data) || []}
                activateRow={this.activateRow}
                desactivateRow={this.desactivateRow}
                editRow={this.editRow}
                showAudit={this.showAudit}
                onMoreClick={(rowData) => {
                  this.setState({
                    showFormsModal: true,
                    formsModalParticipant: rowData,
                  })
                }}
              />
            ) : <Stack sx={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
              height: '100%',
              alignItems: 'center'
            }}>
              <CircularProgress />
            </Stack>}
          </div>
        </CSSTransition>

        <Modal centered show={popupIndirect} onHide={this.hide_PopUpIndirecto}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: 'center' }}>
              No es posible llenar una Encuesta de Salida sin tener una Encuesta
              de Inscripción a Participantes
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hide_PopUpIndirecto}>Aceptar</Button>
          </Modal.Footer>
        </Modal>
        <Modal
          centered
          show={popupNoConfigured}
          onHide={this.hide_PopUpNoConfigured}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: 'center' }}>{popupNoConfiguredData}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hide_PopUpNoConfigured}>Aceptar</Button>
          </Modal.Footer>
        </Modal>

        <BeneficiaryForms
          participant={this.state.formsModalParticipant}
          forms={forms}
          show={this.state.showFormsModal}
          onHide={() =>
            this.setState({
              showFormsModal: false,
              formsModalParticipant: null,
            })
          }
        />
      </Container>
    );
  }
}

function checkIfCanLaboralForm(
  these,
  data,
  checkIfCanLaboralForm,
  type,
  activitiesattendances
) {
  req
    .get(API_URL + 'participant_activity', {
      params: { uuid: data.uuid, activo: true },
    })
    .then((res) => {
      let result = res.data.data || res.data;

      let pass = false;
      let validation = 1;

      if (result.length === 0) {
        pass = true;
      }
      Object.keys(result).forEach(function (key) {
        if (
          result[key].program_type_id === '6241dae9be360f18dd2c4493' ||
          result[key].program_type_id === '6241dacb229bb80ff37d6442'
        ) {
          activitiesattendances.forEach((row) => {
            if (
              row.status === true &&
              row.position === result[key].activity_id &&
              row.participant.uuid === data.uuid
            ) {
              pass = true;
              validation = 1;
            }
          });
        }
      });

      let pass2 = true;
      data.answer_forms.forEach((af) => {
        if (af.type === 'ET') {
          checkIfCanLaboralForm[0].value.forEach((lf) => {
            if (
              af.data['field_' + lf.form_field_id] === lf.answer &&
              af.data['field_' + lf.form_field_id] !== null &&
              af.data['field_' + lf.form_field_id] !== undefined
            ) {
              pass2 = false;
              validation = 2;
            }
          });
        }
      });

      if (pass && pass2) {
        if (type === 'crear') {
          window.location.replace(
            '/formularios/' + these.state.laboralForm[0].value + '/' + data.id
          );
        } else {
          window.location.replace(
            '/formularios/' +
            these.state.laboralForm[0].value +
            '/' +
            data.id +
            '/' +
            data.laboralFormAnswers
          );
        }
      } else {
        if (validation === 1) {
          these.show_PopUpNoConfigured(
            'El participante no ha completado un programa Capacitación Técnico Vocacional y/o Autoempleo/Emprededurismo'
          );
        } else {
          these.show_PopUpNoConfigured(
            'La Encuesta Laboral existente no cumple las condiciones para ser editada: Este beneficiario/a tiene un empleo o negocio'
          );
        }
      }
    });
} // formular, action,  programID(Resiliente),

function checkIfCanExitForm(these, data, activitiesattendances, participants) {
  req
    .get(API_URL + 'participant_activity', {
      params: { uuid: data.uuid, activo: true },
    })
    .then((res) => {
      let result = res.data.data || res.data;

      let pass = false;
      let activity_id = '';
      if (result.length === 0) {
        pass = true;
      }
      Object.keys(result).forEach(function (key) {
        activitiesattendances.forEach((row) => {
          if (
            row.uuid_id === data.uuid &&
            row.status === true &&
            row.position === result[key].activity_id
          ) {
            pass = true;
          }
        });
      });
      if (pass) {
        window.location.replace(
          '/formularios/' + these.state.exitForm[0].value + '/' + data.id
        );
      } else {
        these.show_PopUpNoConfigured(
          'El o la  participante no ha completado un programa'
        );
      }
    });
}


export default withRouter(Wrapper);
